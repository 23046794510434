import { createSelector } from '@reduxjs/toolkit';
import { getAssetsState } from 'store/assets-reducer/assets.selectors';
import { TRootState } from 'store/store';

import { IDashboardState } from './dashboard.reducer';

export const getDashBoardState = (state: TRootState): IDashboardState => state.dashboardReducer;

export const selectDashboardWallet = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.dashboardWallet,
);

export const selectDashboardWalletStatus = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.status,
);

export const selectDashboardWalletError = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.error,
);

export const selectDashboardElectricity = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.dashboardElectricity,
);

export const selectDashboardElectricityStatus = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.status,
);

export const selectDashboardElectricityError = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.error,
);

export const selectDashboardHashRate = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.dashboardHashRate,
);

export const selectActiveMiners = createSelector([getAssetsState], (assetsState) => {
  const miners = Object.values(assetsState.assets).filter((item) => item.status === 'active');
  return { numberActive: miners.length, isActive: miners.length > 0 };
});

export const selectPendingMiners = createSelector([getAssetsState], (assetsState) => {
  const miners = Object.values(assetsState.assets).filter((item) => item.status === 'pending');
  return { numberPending: miners.length, isPending: miners.length > 0 };
});

export const selectDisabledMiners = createSelector([getAssetsState], (assetsState) => {
  const miners = Object.values(assetsState.assets).filter((item) => item.status === 'disabled');
  return { numberDisabled: miners.length, isDisabled: miners.length > 0 };
});

export const selectDashboardHashRateStatus = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.status,
);

export const selectDashboardHashRateError = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.error,
);

export const selectDashboardTotalMining = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.dashboardMining,
);

export const selectDashboardTotalMiningStatus = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.status,
);

export const selectDashboardTotalMiningError = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.error,
);

export const selectSuggestedAmountFormValues = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => {
    if (!dashboardTotalMiningState.formsState.suggestedAmountFormValues) return null;
    return dashboardTotalMiningState.formsState.suggestedAmountFormValues;
  },
);

export const selectSuggestedAmountActive = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.formsState.isSuggestedAmountActive,
);

export const selectSwitcherUsdBtcStatus = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.formsState.switcherUsdBtcStatus,
);
