import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICouponResponseDataMapped } from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import { IInvoiceResponseDataMapped } from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchInvoiceThunkAction } from './invoice.thunk-actions';

export interface IInvoiceState {
  invoiceData: IInvoiceResponseDataMapped | null;
  invoiceFetchStatus: TFetchStatus;
  invoiceError: string | null;
}

export const initialState: IInvoiceState = {
  invoiceData: null,
  invoiceFetchStatus: 'initial',
  invoiceError: null,
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    clearInvoiceStateAction: () => initialState,
    updateInvoiceStateAction: (state, action: PayloadAction<ICouponResponseDataMapped>) => {
      if (!state.invoiceData) return;

      const updatedInvoices = Object.entries(state.invoiceData.invoices).map(([key, invoice]) => [
        key,
        { ...invoice, discount: action.payload.discount },
      ]);

      state.invoiceData = {
        ...state.invoiceData,
        invoices: Object.fromEntries(updatedInvoices),
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoiceThunkAction.pending, (state) => {
      state.invoiceFetchStatus = 'pending';
      state.invoiceError = null;
    });
    builder.addCase(
      fetchInvoiceThunkAction.fulfilled,
      (state, { payload }: PayloadAction<IInvoiceResponseDataMapped>) => {
        state.invoiceFetchStatus = 'fulfilled';
        state.invoiceData = payload;
      },
    );
    builder.addCase(fetchInvoiceThunkAction.rejected, (state, error) => {
      state.invoiceFetchStatus = 'rejected';
      state.invoiceError = JSON.stringify(error);
    });
  },
});

export const { clearInvoiceStateAction, updateInvoiceStateAction } = invoiceSlice.actions;
export const invoiceReducer = invoiceSlice.reducer;
