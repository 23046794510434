import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const SuccessUptimeExplainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 12px;
    padding: 0 16px 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Tittle = styled.h3`
  max-width: 340px;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ModeRestriction = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 7px 12px;
  border-radius: 16px;
  background-color: ${EColors.Gray6};

  @media (max-width: ${BreakPoint.MobileTop}) {

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const RestrictionText = styled.p`
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: ${EColors.Gray2};

  @media (min-width: ${BreakPoint.MobileTop}) {
    max-width: 260px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const SwitchModeButton = styled(Button)`
  width: 100%;
  padding: 12px 16px;
  border-radius: 24px;
`;

export const CancelButton = styled(SwitchModeButton)`
  border-color: ${EColors.Red1};
  color: ${EColors.Red1};
`;
