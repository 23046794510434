import styled from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const ClearAllText = styled(SText)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Red1};
  cursor: pointer;
  font-variant-numeric: lining-nums tabular-nums;
`;
