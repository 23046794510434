import type { FC } from 'react';

import * as S from './MainContentWrapper.styled';

interface IPropsMainContentWrapper {
  children?: React.ReactNode;
}

export const MainContentWrapper: FC<IPropsMainContentWrapper> = ({ children }): JSX.Element => {
  return <S.MainContentWrapper>{children}</S.MainContentWrapper>;
};
