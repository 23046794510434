import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const InfoCard = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 20px 16px 26px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 12px;
    padding: 0;
    background-color: transparent;
  }
`;

export const ElectricityCard = styled(InfoCard)`
  position: relative;
  padding: 16px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 12px 12px 12px 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 12px;
    border-radius: 16px;
    background-color: ${EColors.White};
  }
`;
