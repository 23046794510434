import React, { useEffect } from 'react';
import { ReactComponent as ChatIcon } from 'assets/icons/payment-options-icon/payment-info/chats-circle.svg';
import { ReactComponent as PaymentInfoIcon } from 'assets/icons/payment-options-icon/payment-info/vector.svg';
import { PaymentOption } from 'pages/PagePayment/components/ui/PaymentOption';
import {
  ECryptoPaymentVariant,
  EPaymentType,
  setPaymentDisabledStatusDefault,
} from 'store/payment-reducer/payment.reducer';
import { useAppDispatch } from 'store/store';

import { SPageSection } from 'components/styled/SPageSection';

import * as S from './PaymentOptionsScreen.styled';

interface PaymentOptionsScreenProps {
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
  balance: number;
  amount: number;
  disabledAllPaymentMethodsStatus: boolean;
  t: (key: string) => string;
}

export const PaymentOptionsScreen: React.FC<PaymentOptionsScreenProps> = (props) => {
  const dispatch = useAppDispatch();
  const { handlePaymentButtonClick, balance, amount, t, disabledAllPaymentMethodsStatus } = props;

  const targetLink = document.querySelector('.webim_basic_button') as HTMLAnchorElement;

  useEffect(() => {
    return () => {
      dispatch(setPaymentDisabledStatusDefault());
    };
  }, [dispatch]);

  return (
    <S.SectionsWrapper>
      {disabledAllPaymentMethodsStatus && (
        <SPageSection tittleCeo="payment warning">
          <S.PaymentDisabledWrapper>
            <S.DisabledIcon>
              <PaymentInfoIcon />
            </S.DisabledIcon>
            <S.ButtonWrapper>
              <S.Text>{t('payment.yourInvoiceExceedsOurPaymentSystemLimits')}</S.Text>
              <S.SupportButton
                variant="primary"
                onClick={() => targetLink && targetLink.click()}
                icon={<ChatIcon />}
                svgProps={{
                  svgFill: 'transparent',
                }}
                text={t('payment.contactSupport')}
              />
            </S.ButtonWrapper>
          </S.PaymentDisabledWrapper>
        </SPageSection>
      )}
      <SPageSection tittleCeo="payment options">
        <S.ScreenWrapper>
          {Object.values(EPaymentType).map((method) => {
            return (
              <PaymentOption
                key={method}
                handlePaymentButtonClick={handlePaymentButtonClick}
                paymentType={method}
                amount={amount}
                balance={balance}
                t={t}
              />
            );
          })}
        </S.ScreenWrapper>
      </SPageSection>
    </S.SectionsWrapper>
  );
};
