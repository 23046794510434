import React from 'react';
import { ReactComponent as BtcGray } from 'assets/icons/dashboard-page-icons/btc-gray.svg';
import { ReactComponent as UploadSimpleIcon } from 'assets/icons/dashboard-page-icons/upload-simple.svg';
import { ReactComponent as WalletBlack } from 'assets/icons/dashboard-page-icons/wallet-black.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { SArticleTitle } from 'pages/PageDashboard/components/styled';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import * as S from './WalletArticle.styled';

interface IWalletArticleProps {
  btcValue: string;
  usdValue: string;
  handleWithdrawClick: () => void;
  t: (key: string) => string;
}

export const WalletArticle: React.FC<IWalletArticleProps> = ({
  btcValue,
  usdValue,
  handleWithdrawClick,
  t,
}) => {
  const { innerWidth } = useInnerWidth();

  const isMobileWide = innerWidth <= parseInt(BreakPoint.MobileTop);

  return (
    <S.WalletArticle as="article">
      <SArticleTitle as="h3">
        <WalletBlack />
        {t('wallet.walletSectionTitle')}
      </SArticleTitle>
      <S.BalanceList as="dl">
        <S.BalanceTitle as="dt">
          <BtcGray />
          {t('wallet.totalBalanceTitle')}
        </S.BalanceTitle>
        <S.BalanceDescription as="dd">
          <S.ValuesWrapper>
            <S.BtcValue>{btcValue}</S.BtcValue>
            <S.UsdValue>{usdValue}</S.UsdValue>
          </S.ValuesWrapper>
          {isMobileWide ? (
            <S.WalletButton
              text={t('withdrawContent.withdraw')}
              icon={<UploadSimpleIcon />}
              onClick={handleWithdrawClick}
              variant="borderless"
              svgProps={{
                svgFill: 'transparent',
              }}
            />
          ) : (
            <S.WalletButton
              text={t('withdrawContent.withdraw')}
              icon={<UploadSimpleIcon />}
              onClick={handleWithdrawClick}
              variant="secondary"
              svgProps={{
                svgFill: EColors.Gray5,
                activeSvgFill: EColors.Gray4,
              }}
            />
          )}
          {/* <S.WalletButton
            text={t('withdrawContent.withdraw')}
            icon={<UploadSimpleIcon />}
            onClick={handleWithdrawClick}
            variant="secondary"
            svgProps={{
              svgFill: EColors.Gray5,
              activeSvgFill: EColors.Gray4,
            }}
          /> */}
        </S.BalanceDescription>
      </S.BalanceList>
    </S.WalletArticle>
  );
};
