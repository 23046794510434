import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { useOuterClick } from 'hooks/useOuterClick';
import { openCartContentPopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { CartIcon } from 'components/logic/Cart/components/ui/CartIcon';
import { CartTooltip } from 'components/logic/Cart/components/ui/CartTooltip/CartTooltip';
import { useCartLogic } from 'components/logic/Cart/hooks/useCartLogic';
import { AppRoute } from 'utils/route/app-route';

import * as S from './Cart.styled';

export const Cart: FC = () => {
  const { innerWidth } = useInnerWidth();
  const isMobile = innerWidth <= parseInt(BreakPoint.MobileTop);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const cartContentRef = useRef<HTMLDivElement>(null);
  const {
    isEmpty,
    cartItemsAllQuantity,
    cartListWithMinersData,
    isTooltipOpen,
    isCartPending,
    handleTooltipClose,
    handleTooltipOpen,
    handleIncrement,
    handleDecrement,
    handleDeleteAllSameItems,
    handleDeleteAllItems,
    handleCheckoutClick,
    setIsTooltipOpen,
    t,
  } = useCartLogic();

  useOuterClick({ excludedElements: [cartContentRef], cb: handleTooltipClose });

  const isPaymentPage = location.pathname === AppRoute.Payment();
  const isCheckoutPage = location.pathname === AppRoute.PurchaseCheckout();
  const isPurchasePersonalInfoFormPage = location.pathname === AppRoute.PurchasePersonalInfo();

  const handleCartPopupOpen = () => {
    if (!isMobile || isPaymentPage || isCheckoutPage || isPurchasePersonalInfoFormPage) return;
    dispatch(openCartContentPopupAction());
  };

  return (
    <S.CartContentWrapper ref={cartContentRef}>
      {!isMobile && !isPaymentPage && !isCheckoutPage && !isPurchasePersonalInfoFormPage && (
        <CartTooltip
          isTooltipOpen={isTooltipOpen}
          isEmpty={isEmpty}
          isCartPending={isCartPending}
          cartListWithMinersData={cartListWithMinersData}
          handleDecrement={handleDecrement}
          handleDeleteAllItems={handleDeleteAllItems}
          handleDeleteAllSameItems={handleDeleteAllSameItems}
          handleIncrement={handleIncrement}
          handleTooltipClose={handleTooltipClose}
          handleCheckoutClick={handleCheckoutClick}
          setIsTooltipOpen={setIsTooltipOpen}
          t={t}
        />
      )}

      <S.CartContent
        onClick={isMobile ? handleCartPopupOpen : handleTooltipOpen}
        $cursorPointer={!isPaymentPage && !isCheckoutPage && !isPurchasePersonalInfoFormPage}
      >
        <CartIcon isEmptyCart={isEmpty} count={cartItemsAllQuantity} />
        {!isMobile && (
          <S.CartText $isEmpty={isEmpty}>
            {cartItemsAllQuantity} {cartItemsAllQuantity > 1 ? t('cart.miners') : t('cart.miner')}
          </S.CartText>
        )}
      </S.CartContent>
    </S.CartContentWrapper>
  );
};
