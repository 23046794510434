import { SInfoArticle, SInfoBadge } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const WalletArticle = styled(SInfoArticle)`
  flex: 1 1 422px;
  gap: 16px;
  background-color: ${EColors.White};

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-basis: 342px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex: 0 0 auto;
    justify-content: flex-start;
    padding: 12px;
    border-radius: 16px;
  }
`;

export const BalanceList = styled(SInfoBadge)`
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0;
  }
`;

export const BalanceTitle = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray1Text};
`;

export const BalanceDescription = styled.dd`
  display: flex;
  justify-content: space-between;
  gap: 2px;

  @media (max-width: ${BreakPoint.MobileWide}) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const BtcValue = styled(SText)`
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`;

export const UsdValue = styled(SText)`
  font-size: 16px;
  line-height: 20px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const WalletButton = styled(Button)`
  align-self: baseline;
  padding: 7px 12px;
  border-radius: 16px;

  @media (min-width: ${BreakPoint.TabletTop}) {
    margin-top: 4px;
  }

  @media (max-width: ${BreakPoint.MobileWide}) {
    margin-top: 0;
    padding: 0;
  }
`;
