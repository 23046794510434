import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InvoiceIcon } from 'assets/icons/button-icons/invoice.svg';
import { EColors } from 'styles/style-variables/colors';

import { AppRoute } from 'utils/route/app-route';

import * as S from './PersonalInformationHeader.styled';

interface IPropsPersonalInformationHeader {
  t: (t: string) => string;
}

export const PersonalInformationHeader: FC<IPropsPersonalInformationHeader> = ({
  t,
}): JSX.Element => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(AppRoute.Purchase(), { replace: true });
  };

  return (
    <S.PersonalInformationHeader>
      <S.Title>{t('purchase.billingAddress')}</S.Title>
      <S.BackButton
        onClick={handleBackClick}
        variant="bordered"
        text={t('purchase.backToOrder')}
        icon={<InvoiceIcon />}
        svgProps={{
          svgFill: EColors.White,
        }}
      />
    </S.PersonalInformationHeader>
  );
};
