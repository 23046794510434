import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CardIcon } from 'assets/icons/payment-options-icon/card.svg';
import { ReactComponent as ApplePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/apple-pay.svg';
import { ReactComponent as GooglePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/google-pay.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/payment-options-icon/card-pay-icons/master-card.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/payment-options-icon/card-pay-icons/visa.svg';
import { usePaymentDisabled } from 'pages/PagePayment/hooks/usePaymentDisabled';
import { useUnloadStatus } from 'pages/PagePayment/hooks/useUnloadStatus';
import { EPaymentMethod, EPaymentType } from 'store/payment-reducer/payment.reducer';
import { selectPaymentCardFetchStatus } from 'store/payment-reducer/payment.selectors';

import { SWarningText } from 'components/styled/SWarningText/SWarningText';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface CardPaymentProps {
  t: (key: string) => string;
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  amount: number;
}

export const CardPaymentCard: React.FC<CardPaymentProps> = ({
  t,
  handlePaymentButtonClick,
  amount,
}) => {
  const [isDisable, setIsDisable] = React.useState(false);
  const paymentCardStatus = useSelector(selectPaymentCardFetchStatus);
  const { isLoading } = useUnloadStatus(paymentCardStatus);

  const { getPaymentDisabledStatus } = usePaymentDisabled({
    paymentAmount: amount,
    paymentMethod: EPaymentMethod.Mercuryo,
  });

  useEffect(() => {
    setIsDisable(getPaymentDisabledStatus());
  }, [getPaymentDisabledStatus]);

  return (
    <S.CardWrapper>
      <S.CardContent>
        <S.CardTitleWrapper>
          <CardIcon />
          <S.CartTitle>{t('payment.card')}</S.CartTitle>
        </S.CardTitleWrapper>
        <S.CardText>{t('payment.payInvoiceWithBankCard')}</S.CardText>
        <S.IconsContainer $iconGap={9}>
          <VisaIcon width={41.666} height={13.5} />
          <MasterCardIcon width={29.125} height={18} />
          <ApplePayIcon width={37.489} height={24} />
          <GooglePayIcon width={38.987} height={15} />
        </S.IconsContainer>
      </S.CardContent>

      {isLoading ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.PayButtonWrapper>
          <S.WarningTextWrapper>
            <SWarningText $isDisabled={isDisable}>{t('payment.maxPaymentCard')}</SWarningText>
            <S.WarningTextBlack>{t('payment.dailyLimit')}</S.WarningTextBlack>
          </S.WarningTextWrapper>
          <S.PayButton
            text={t('payment.cardPaymentTitle')}
            disabled={isDisable}
            onClick={() => handlePaymentButtonClick(EPaymentType.Card)}
          />
        </S.PayButtonWrapper>
      )}
    </S.CardWrapper>
  );
};
