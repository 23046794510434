import { SListTitle } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const LaunchPendingWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 2px 8px;
  grid-template-areas:
    'icon title'
    'icon hashrate';

  & > svg {
    align-self: left;
    grid-area: icon;
    width: 32px;
    height: 32px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 0 8px;
    grid-template-areas:
      'icon title'
      'hashrate hashrate';

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 4px;
    grid-template-areas:
      'icon title'
      'hashrate hashrate';

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Title = styled(SListTitle)`
  grid-area: title;
  text-wrap: nowrap;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  gap: 8px;
  grid-area: hashrate;
`;

export const HashrateValue = styled(SText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const MinersCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;

  & > svg {
    width: 16px;
    height: 16px;
    padding: 0.833px 2px 1.333px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 2px;
    padding: 2px 4px;
    background-color: ${EColors.Gray5};

    & > svg {
      width: 12px;
      height: 12px;
      padding: 0.625px 1.5px 1px;
    }
  }
`;

export const CountNumber = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }
`;
