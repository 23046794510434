import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const PersonalInformationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  color: ${EColors.White};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const BackButton = styled(Button)`
  padding: 7px 12px;
  border-color: ${EColors.White};
  font-size: 14px;
  line-height: 18px;
  color: ${EColors.White};

  & > svg {
    width: 16px;
    height: 16px;
  }
`;
