import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const MainContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 432px;
  padding: 24px;
  border-radius: 32px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    padding: 24px 16px 64px;
    border-radius: 0 0 24px 24px;
  }
`;
