import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const MinedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
  }
`;

export const MinedTitle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  & svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: center;
  }
`;

export const TittleText = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 163px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
    width: 100%;
  }
`;

export const MinedValueUsd = styled(SText)`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const MinedValueBtc = styled(SText)`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;
