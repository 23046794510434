import type { FC } from 'react';
import { ReactComponent as HashrateIcon } from 'assets/icons/dashboard-page-icons/gauge.svg';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/dashboard-page-icons/miner-white.svg';

import * as S from './HashrateAmount.styled';

interface IPropsHashrateAmount {
  hashrate: string;
  activeMinersCount: string;
  t: (key: string) => string;
}

export const HashrateAmount: FC<IPropsHashrateAmount> = ({ hashrate, activeMinersCount, t }) => {
  return (
    <S.HashrateAmountWrapper>
      <S.TittleWrapper>
        <HashrateIcon />
        <S.Title as="dt">{t('mining.hashRateAmountTitle')}</S.Title>
      </S.TittleWrapper>
      <S.ValuesWrapper>
        <S.AmountValue as="dd">{hashrate}</S.AmountValue>
        <S.MinersCount as="dd">
          <MinerWhiteIcon />
          <S.CountNumber>{activeMinersCount}</S.CountNumber>
        </S.MinersCount>
      </S.ValuesWrapper>
    </S.HashrateAmountWrapper>
  );
};
