import type { FC } from 'react';
import { ReactComponent as EllipseIcon } from 'assets/icons/cart/ellipse-100.svg';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/cart/shopping-cart.svg';

import * as S from './CartIcon.styled';

interface IPropsCartIcon {
  isEmptyCart: boolean;
  count: number;
}

export const CartIcon: FC<IPropsCartIcon> = ({ isEmptyCart, count }): JSX.Element => {
  return (
    <S.CartIconWrapper>
      <ShoppingCartIcon width={24} height={24} />
      {!isEmptyCart && (
        <S.IconsWrapper $count={count}>
          <EllipseIcon />
          <S.CountIconText>{count > 9 ? `${Math.min(count, 9)}+` : count}</S.CountIconText>
        </S.IconsWrapper>
      )}
    </S.CartIconWrapper>
  );
};
