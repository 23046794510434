import { FC } from 'react';
import { ReactComponent as ReceiptIcon } from 'assets/icons/purchase-page-icons/receipt.svg';
import { ReactComponent as SpinnerSmallIcon } from 'assets/icons/spinner/spinner-white-small.svg';

import { EButtonAnimations } from 'components/ui/Button/Button.types';

import * as S from './CheckoutButton.styled';

interface ICheckoutButtonProps {
  isEmpty: boolean;
  isCartPending: boolean;
  handleCheckoutClick: () => void;
}

export const CheckoutButton: FC<ICheckoutButtonProps> = ({
  isEmpty,
  isCartPending,
  handleCheckoutClick,
}): JSX.Element => {
  return (
    <>
      {isCartPending ? (
        <S.CartCheckoutButton
          variant="primary"
          text="Checkout"
          icon={<SpinnerSmallIcon />}
          onClick={handleCheckoutClick}
          svgProps={{
            withAnimation: EButtonAnimations.rotate,
          }}
          disabled={isEmpty || isCartPending}
        />
      ) : (
        <S.CartCheckoutButton
          variant="primary"
          text="Checkout"
          icon={<ReceiptIcon />}
          onClick={handleCheckoutClick}
          svgProps={{
            svgFill: 'transparent',
          }}
          disabled={isEmpty || isCartPending}
        />
      )}
    </>
  );
};
