import React from 'react';
import { isShowLanguageSwitcher } from 'constants/i18next/i18next.consts';
import { useBlockContentScroll } from 'hooks/useBlockContentScroll';

import { LanguageSwitcher } from 'components/logic/InnerLayout/components/ui/LanguageSwitcher';
import { MenuLogo } from 'components/logic/InnerLayout/components/ui/MenuLogo';
import { MenuProfileLink } from 'components/logic/InnerLayout/components/ui/MenuProfileLink';
import { Navigation } from 'components/logic/InnerLayout/components/ui/Navigation';
import { NavigationFooter } from 'components/logic/InnerLayout/components/ui/NavigationFooter';

import * as S from './MainMenu.styled';

interface IMainMenuProps {
  handleLogout: () => void;
  isLoggingOut: boolean;
  isMainMenuOpened: boolean;
  handleResetBuyingFlow: () => void;
}

export const MainMenu: React.FC<IMainMenuProps> = ({
  handleLogout,
  isLoggingOut,
  isMainMenuOpened,
  handleResetBuyingFlow,
}) => {
  useBlockContentScroll('main-menu', isMainMenuOpened);
  return (
    <>
      <S.LogoWrapper>
        <MenuLogo />
        {isShowLanguageSwitcher && (
          <S.LngSwitcherWrapper>
            <LanguageSwitcher />
          </S.LngSwitcherWrapper>
        )}
      </S.LogoWrapper>
      <MenuProfileLink />
      <Navigation handlePurchaseLinkClick={handleResetBuyingFlow} />
      <NavigationFooter handleLogout={handleLogout} isLoggingOut={isLoggingOut} />
    </>
  );
};
