import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IWebimState } from './webim.reducer';

export const getWebimState = (state: TRootState): IWebimState => state.webimReducer;

export const selectWebimShouldUpdate = createSelector([getWebimState], (webimState) => ({
  shouldUpdateState: webimState.shouldUpdateState,
}));

export const selectWebimVisitor = createSelector([getWebimState], (webimState) => ({
  webimVisitor: webimState.webimVisitor,
}));

export const selectWebimInitStatus = createSelector([getWebimState], (webimState) => ({
  status: webimState.status,
}));
