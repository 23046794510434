import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const CartItemWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

export const ItemMainContentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const MinerPicture = styled.picture`
  grid-area: image;
  width: 40px;
  height: 40px;

  & img {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    font-size: 12px;
  }
`;

export const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: max-content;
  font-style: normal;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-width: auto;
    max-width: 120px;
  }
`;

export const ItemNameTittle = styled(SText)`
  font-size: 14px;
  line-height: 18px;
  color: ${EColors.Gray1Text};
`;

export const ItemSummTittle = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const PlusMinusWrapper = styled.div`
  display: flex;
  gap: 2px;
  cursor: pointer;
`;

export const CountTittle = styled(SText)`
  width: 27px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: ${EColors.Blue2NewBrand};
  user-select: none;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const TrashWrapper = styled.div`
  cursor: pointer;
`;
