import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalInformationForm } from 'pages/PagePurchase/components/logic/PersonalInformationForm';
import { PersonalInformationHeader } from 'pages/PagePurchase/components/ui/PersonalInformationHeader';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';

import { SPage } from 'components/styled/SPage';

export const PersonalInformationScreen: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    void dispatch(requestProfileDataThunkAction());
  }, [dispatch]);

  return (
    <SPage headerComponent={<PersonalInformationHeader t={t} />}>
      <PersonalInformationForm t={t} />
    </SPage>
  );
};
