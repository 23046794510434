import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SSpinnerWhite } from 'components/styled/SSpinnerWhite';
import { Button } from 'components/ui/Button';

export const InfoTitle = styled.h1`
  margin-bottom: 34px;
  font-family: ${FontFamily.Montserrat};
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: #fff;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-bottom: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  }
`;
export const List = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 48px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-template-columns: auto 1fr;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: auto;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 18px;
  }
`;

export const Term = styled.dt`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #f4f4f7;

  & svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-variant-numeric: lining-nums tabular-nums;

    & > svg {
      display: none;
    }
  }
`;

export const BtcEarnedValue = styled.dd`
  opacity: 0.8;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #e5eaf1;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const UsdEarnedValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  color: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ReferralsValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #e5eaf1;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
  }
`;

export const LinkGroup = styled.div`
  display: grid;
  align-items: center;
  gap: 8px;
  grid-template-columns: 320px 122px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column: 1/-1;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 1fr;
  }
`;

export const AffiliateTitle = styled.dt`
  grid-column: 1/-1;
  justify-self: start;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-variant-numeric: lining-nums tabular-nums;
  color: #fff;
`;

export const AffiliateLinkValue = styled.dd`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: start;
  min-height: 42px;
  padding: 12px 16px;
  border-radius: 24px;
  background: #03349e;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  word-break: break-all;
  color: #fff;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 32px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const AffiliateLinkLoader = styled(SSpinnerWhite)`
  width: 18px;
  height: 18px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 16px;
    height: 16px;
  }
`;

export const ButtonWrapper = styled.dd`
  align-self: start;
`;

export const AffiliateLinkCopyButton = styled(Button)`
  min-height: 42px;
  padding: 12px 16px;
  border-radius: 24px;
  background: #fff;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #0e46c0;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    min-height: 32px;
    padding: 8px 12px;
    line-height: 16px;
  }
`;
