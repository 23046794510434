import type { FC } from 'react';

import * as S from './SPage.styled';

interface IPropsSPage {
  pageTittle?: string;
  headerComponent?: React.ReactNode;
  children?: React.ReactNode;
  headerAlignItems?: string;
}

export const SPage: FC<IPropsSPage> = ({
  children,
  pageTittle,
  headerComponent,
  headerAlignItems,
}): JSX.Element => {
  return (
    <>
      <S.ContentHeader $alignItems={headerAlignItems}>
        {pageTittle && <S.Title>{pageTittle}</S.Title>}
        {headerComponent}
      </S.ContentHeader>
      <S.ContentSectionsWrapper>{children}</S.ContentSectionsWrapper>
    </>
  );
};
