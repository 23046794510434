import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';
import { initWebimStateAction } from 'store/webim-reducer/webim.reducer';
import { selectWebimInitStatus } from 'store/webim-reducer/webim.selectors';

import { setWebimCookies } from 'utils/storage/webim-cookies/set-webim-cookies';

/**
 * Hook to initialize WebIM state if needed.
 * When the user is authenticated, it also sets WebIM cookies at regular intervals.
 */
export const useWebImButton = (): void => {
  const dispatch = useAppDispatch();
  const { status } = useSelector(selectWebimInitStatus);
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const profile = useSelector(selectProfile);

  /**
   * Effect to initialize WebIM state if needed.
   */
  useEffect(() => {
    if (!status) {
      dispatch(initWebimStateAction());
    }
  }, [dispatch, status]);

  /**
   * Effect to set WebIM cookies at regular intervals when the user is authenticated.
   */
  useEffect(() => {
    if (userAuthStatus !== 'authorized-verified') return;

    const intervalId = setInterval(() => {
      const WEBIM_VISIT_SESSION_ID = localStorage.getItem('WEBIM_VISIT_SESSION_ID');
      const WEBIM_VISITOR = localStorage.getItem('WEBIM_VISITOR');

      setWebimCookies(WEBIM_VISIT_SESSION_ID, WEBIM_VISITOR);
    }, SECOND_IN_MILLISECONDS);

    return () => {
      clearInterval(intervalId);
    };
  }, [userAuthStatus]);

  /**
   * Effect to remove WebIM footer link.
   */
  useEffect(() => {
    let webimFooter: Element | null;
    const intervalId = setInterval(() => {
      webimFooter = document.querySelector('.webim-footer');
      if (!webimFooter) return;
      webimFooter.remove();
      clearInterval(intervalId);
    }, SECOND_IN_MILLISECONDS / 2);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  /**
   * Effect to set the email in the input field.
   */
  useEffect(() => {
    const inputEmail = document.querySelector('input[name="email"]') as HTMLInputElement;

    if (inputEmail) {
      inputEmail.value = profile.email;
    }
  }, [profile.email]);

  /**
   * Effect to change the color of the WebIM chat buttons.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      const webimChatSvgs = document.querySelectorAll('.webim-chat-action .webim-ico');

      if (!webimChatSvgs.length) return;

      webimChatSvgs.forEach((svgElement) => {
        const useElement = svgElement.querySelector('use');
        if (useElement) {
          useElement.style.fill = 'white';
        }
      });
    }, SECOND_IN_MILLISECONDS);

    return () => clearInterval(intervalId);
  }, []);
};
