import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChatIcon } from 'assets/icons/login-page-icons/chats-circle.svg';
import { useWebImButton } from 'hooks/useWebimButton';

import * as S from './WebimLink.styled';

export const WebimLink: FC = () => {
  useWebImButton();
  const { t } = useTranslation();
  return (
    <S.WebimLinkWrapper>
      <ChatIcon />
      <S.WebimLink className="webim_button webim_basic_button" to="#" rel="webim">
        {t('login.contactSupport')}
      </S.WebimLink>
    </S.WebimLinkWrapper>
  );
};
