export const cutUrl = (url: string, excludePaths: string[] = []): string | null => {
  const mockUrl = 'https://example.example.com';
  const localhostRegex = /^https?:\/\/localhost:3000/g;
  let changedUrl;

  if (url.match(localhostRegex)) {
    changedUrl = url.replace(/^https?:\/\/localhost:3000/g, mockUrl);
  }

  const regex = /^(https?:\/\/(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/.*)$/;
  const match = changedUrl ? changedUrl.match(regex) : url.match(regex);

  if (match) {
    const path = match[2];

    if (excludePaths.length > 0) {
      for (const allowedPath of excludePaths) {
        if (path.startsWith(allowedPath)) {
          return path.replace(allowedPath, '');
        }
      }
    }
    return path;
  }

  return null;
};
