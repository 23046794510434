import { SVGProps } from 'react';
import { css, styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const CartIconWrapper = styled.div`
  position: relative;
  max-height: 24px;
`;

export const IconsWrapper = styled.div<{ $count: number }>`
  position: absolute;

  @media (max-width: ${BreakPoint.MobileTop}) {
    ${({ $count }) =>
      $count > 9
        ? css`
            bottom: 12px;
            left: 7px;
          `
        : css`
            bottom: 12px;
            left: 9px;
          `}

    & > :nth-child(1) {
      display: none;
    }
  }

  @media (min-width: ${BreakPoint.MobileTop}) {
    bottom: 10px;
    left: 12px;

    & > :nth-child(2) {
      display: none;
    }
  }
`;

export const CountIconText = styled(SText)`
  display: inline-flex;
  padding: 2px;
  border: 2px solid ${EColors.Blue3};
  border-radius: 4px;
  background: ${EColors.Red1};
  font-size: 10px;
  font-weight: 600;
  line-height: 8px;
  color: ${EColors.White};
  font-variant-numeric: lining-nums tabular-nums;
`;
