import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const DatesContainer = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  border-radius: 0 0 16px 16px;
  background-color: #fff;
`;

export const HistoryDay = styled.li`
  display: flex;
  flex-direction: column;
`;

export const DateRow = styled.time`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #272727;
`;

export const TransactionItem = styled.dl`
  display: grid;
  grid-template-areas:
    'date type'
    'id id'
    'message message';
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const TransactionDate = styled.dd`
  align-self: center;
  grid-area: date;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const TransactionType = styled.dt`
  grid-area: type;
  justify-self: end;
`;

export const TransactionId = styled.dd`
  align-self: center;
  grid-area: id;
  margin-top: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #646464;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const TransactionMessage = styled.dd`
  grid-area: message;
  margin-top: 2px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;
