import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { ZIndex } from 'styles/style-variables/z-index';

import { Button } from 'components/ui/Button';

export const SliderContainer = styled.div`
  position: relative;
  z-index: ${ZIndex.Slider};
  width: 100%;
  padding: 16px 0;
  background-color: ${EColors.Gray5};
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderTrack = styled.div`
  display: inline-flex;
  gap: 8px;
`;

export const SliderItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  min-width: 180px;

  &:first-of-type {

    & button {
      margin-left: 16px;
    }
  }

  &:last-of-type {

    & button {
      margin-right: 16px;
    }
  }
`;

export const TabButton = styled(Button)`
  gap: 8px;
  padding: 7px 12px;
  font-size: 12px;
  white-space: nowrap;
  color: ${EColors.Gray1Text};
  user-select: none;

  & svg path {
    fill: ${EColors.Gray1Text};
  }

  &:hover,
  &:focus {
    background-color: ${EColors.Gray5};
  }

  &:active {
    background-color: ${EColors.Gray4};
  }

  &:disabled {
    background-color: ${EColors.Gray3};
    color: ${EColors.White};

    & svg path {
      fill: ${EColors.White};
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 4px;
    width: 100%;
    font-size: 10px;

    &:hover,
    &:focus {
      background-color: ${EColors.White};
    }

    &:active {
      background-color: ${EColors.Gray4};
    }

    &:disabled {
      background-color: ${EColors.Gray3};
      color: ${EColors.White};

      & svg path {
        fill: ${EColors.White};
      }
    }
  }
`;
