import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { CurrencyBadge } from 'components/ui/CurrencyBadge';

export const InputWrapper = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  width: 100%;

  & > svg {
    position: absolute;
    top: 50%;
    right: 12px;
    flex-shrink: 0;
    transition: all ${TransitionTime.Hover};
    transform: translateY(-50%);

    & path {
      ${({ $isDisabled }) =>
        $isDisabled &&
        css`
          fill: #c9c9c9;
        `}
      transition: all ${TransitionTime.Hover};
    }
  }
`;

export const Input = styled.input<{
  $hasErrors: boolean;
  $hasCurrencyBadge: boolean;
  $hasRightIcon: boolean;
  $hasRightText: boolean;
}>`
  appearance: none;
  width: 100%;
  height: 42px;
  padding: 11px 15px;
  ${({ $hasRightIcon, $hasRightText }) => {
    if ($hasRightIcon) {
      return css`
        padding-right: 40px;
      `;
    }
    if ($hasRightText) {
      return css`
        padding-right: 120px;
      `;
    }
  }};
  ${({ $hasCurrencyBadge }) =>
    $hasCurrencyBadge &&
    css`
      padding-left: 92px;
    `};
  border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#c9c9c9')};
  border-radius: 24px;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  transition: all ${TransitionTime.Hover};
  outline: none;
  vertical-align: center;

  &::placeholder {
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #a1a1a1;
    transition: all ${TransitionTime.Hover};
  }

  &[type='password']::-ms-reveal {
    display: none;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    height: 32px;
    padding: 7px 11px;
    ${({ $hasRightIcon, $hasRightText }) => {
      if ($hasRightIcon) {
        return css`
          padding-right: 36px;
        `;
      }
      if ($hasRightText) {
        return css`
          padding-right: 120px;
        `;
      }
    }};
    ${({ $hasCurrencyBadge }) =>
      $hasCurrencyBadge &&
      css`
        padding-left: 84px;
      `};
    font-size: 12px;
    line-height: 16px;

    &::placeholder {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &:hover {
    border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#a1a1a1')};
  }

  &:focus {
    border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#3069E5')};
    box-shadow: 0 0 4px 0 rgb(14 70 192 / 40%);

    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    border-color: #c9c9c9;
    background-color: #eaeaea;
    color: #c9c9c9;

    &::placeholder {
      color: #c9c9c9;
    }

    & + span {
      color: #c9c9c9;
    }
  }

  &:-webkit-autofill,
  &:autofill,
  &:-webkit-autofill:focus,
  &:autofill:focus {
    background-color: #fff;
    box-shadow: 0 0 0 1000px #fff inset;
    font-family: ${FontFamily.Montserrat};
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #272727;
    background-clip: text;
    -webkit-text-fill-color: #272727;

    &:focus {
      box-shadow:
        0 0 0 1000px #fff inset,
        0 0 4px 0 rgb(14 70 192 / 40%);
    }
  }
`;

export const InputCurrencyBadge = styled(CurrencyBadge)`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  transition: all ${TransitionTime.Hover};

  @media (max-width: ${BreakPoint.MobileTop}) {
    left: 4px;
  }
`;

export const SecondaryText = styled(SText)<{ rightTextColor: string }>`
  position: absolute;
  top: 50%;
  right: 16px;
  max-width: 96px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  white-space: nowrap;
  color: ${({ rightTextColor }) => rightTextColor};
  transition: all ${TransitionTime.Hover};
  text-overflow: ellipsis;
  transform: translateY(-50%);
`;

export const EyeButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 12px;
  transform: translateY(-50%);

  @media (max-width: ${BreakPoint.MobileTop}) {
    right: 4px;
    padding: 8px;
  }
`;
