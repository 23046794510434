import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as HeaderLogo } from 'assets/icons/logo-icons/new-cuverse-logo-white.svg';
import { ReactComponent as MenuBurger } from 'assets/icons/menu-icons/menu-burger.svg';
import { ReactComponent as MenuCross } from 'assets/icons/menu-icons/menu-cross.svg';
import { isShowLanguageSwitcher } from 'constants/i18next/i18next.consts';

import { Cart } from 'components/logic/Cart';
import { LanguageSwitcher } from 'components/logic/InnerLayout/components/ui/LanguageSwitcher';
import { Profile } from 'components/ui/Profile';
import { AppRoute } from 'utils/route/app-route';

import * as S from './InnerHeader.styled';

export interface IInnerHeaderProps {
  isMainMenuOpened: boolean;
  handleMainMenuOpen: () => void;
}

export const InnerHeader: React.FC<IInnerHeaderProps> = ({
  handleMainMenuOpen,
  isMainMenuOpened,
}) => {
  const location = useLocation();

  const isPaymentPage = location.pathname === AppRoute.Payment();

  return (
    <S.Wrapper>
      <S.Menu>
        <S.MenuButton
          variant="borderless"
          icon={isMainMenuOpened ? <MenuCross /> : <MenuBurger />}
          onClick={handleMainMenuOpen}
        />
        <S.LogoLink to={AppRoute.Home()}>
          <HeaderLogo />
        </S.LogoLink>
      </S.Menu>

      <S.Profile>
        {isShowLanguageSwitcher && (
          <S.LngSwitcherDesktop>
            <LanguageSwitcher />
          </S.LngSwitcherDesktop>
        )}
        <Cart />
        <S.ProfileLink to={AppRoute.Profile()}>
          <Profile variant="header" />
        </S.ProfileLink>
      </S.Profile>
    </S.Wrapper>
  );
};
