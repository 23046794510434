import React from 'react';
import { ReactComponent as DepositIcon } from 'assets/icons/dashboard-page-icons/deposit-type.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/dashboard-page-icons/Invoice-alt.svg';
import { ReactComponent as ElectricityDepositDarkIcon } from 'assets/icons/dashboard-page-icons/lightning-alt.svg';
import { ReactComponent as ElectricityFeeCashbackIcon } from 'assets/icons/dashboard-page-icons/lightning-alt-cashback.svg';
import { ReactComponent as ElectricityFeeDarkIcon } from 'assets/icons/dashboard-page-icons/lightning-alt-dark.svg';
import { ReactComponent as MiningIcon } from 'assets/icons/dashboard-page-icons/mining-type.svg';
import { ReactComponent as WithdrawalIcon } from 'assets/icons/dashboard-page-icons/withdraw-type.svg';
import { ReactComponent as AffiliateIcon } from 'assets/icons/transaction-history-filters-icons/share-network.svg';
import { ECategory } from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';

import * as S from './TransactionTypeBadge.styled';

interface ITransactionTypeBadgeProps {
  transactionType: ECategory;
  className?: string;
  t: (key: string) => string;
}

export const TransactionTypeBadge: React.FC<ITransactionTypeBadgeProps> = ({
  transactionType,
  className,
  t,
}) => {
  switch (transactionType) {
    case ECategory.deposit:
      return (
        <S.Wrapper className={className}>
          <DepositIcon />
          {t('transactionHistory.deposit')}
        </S.Wrapper>
      );
    case ECategory.withdrawal:
      return (
        <S.Wrapper className={className}>
          <WithdrawalIcon />
          {t('transactionHistory.withdrawal')}
        </S.Wrapper>
      );
    case ECategory.mining:
      return (
        <S.Wrapper className={className}>
          <MiningIcon />
          {t('transactionHistory.mining')}
        </S.Wrapper>
      );
    case ECategory.invoice:
      return (
        <S.Wrapper className={className}>
          <InvoiceIcon />
          {t('transactionHistory.invoice')}
        </S.Wrapper>
      );
    case ECategory.electricity_fee:
      return (
        <S.Wrapper className={className}>
          <ElectricityFeeDarkIcon />
          {t('transactionHistory.electricityFee')}
        </S.Wrapper>
      );
    case ECategory.electricity_fee_cashback:
      return (
        <S.Wrapper className={className}>
          <ElectricityFeeCashbackIcon />
          {t('transactionHistory.cashback')}
        </S.Wrapper>
      );
    case ECategory.electricity_fee_deposit:
      return (
        <S.Wrapper className={className}>
          <ElectricityDepositDarkIcon />
          {t('transactionHistory.deposit')}
        </S.Wrapper>
      );
    case ECategory.affiliate:
      return (
        <S.Wrapper className={className}>
          <AffiliateIcon />
          {t('transactionHistory.affiliate')}
        </S.Wrapper>
      );
    default:
      return <S.Wrapper className={className}>{transactionType}</S.Wrapper>;
  }
};
