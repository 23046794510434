import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { TOTAL_HISTORY_ITEMS_ON_PAGE_MOBILE } from 'pages/PageAffiliateProgram//constants/referral-history.consts';
import { HistoryTableDesktop } from 'pages/PageAffiliateProgram/components/ui/HistoryTableDesktop/HistoryTableDesktop';
import { HistoryTableMobile } from 'pages/PageAffiliateProgram/components/ui/HistoryTableMobile/HistoryTableMobile';
import { TOTAL_HISTORY_ITEMS_ON_PAGE_DESKTOP } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import {
  selectReferralHistory,
  selectReferralLinkFetchStatus,
} from 'store/affiliate-reducer/affiliate.selectors';
import { fetchReferralHistory } from 'store/affiliate-reducer/affiliate.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { Pagination } from 'components/ui/Pagination';

import * as S from './HistorySection.styled';

export const HistorySection: FC = () => {
  const dispatch = useAppDispatch();
  const { innerWidth } = useInnerWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const referralHistoryStatus = useSelector(selectReferralLinkFetchStatus);
  const referralHistoryData = useSelector(selectReferralHistory);
  const { t } = useTranslation();
  const isPending = referralHistoryStatus === 'pending';
  const isMobile = innerWidth <= parseInt(BreakPoint.MobileTop);

  const reversedHistoryData = Array.isArray(referralHistoryData)
    ? [...referralHistoryData].reverse()
    : [];

  const handleSetCurrentPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  const totalPages = reversedHistoryData
    ? Math.ceil(
        reversedHistoryData?.length /
          (isMobile ? TOTAL_HISTORY_ITEMS_ON_PAGE_MOBILE : TOTAL_HISTORY_ITEMS_ON_PAGE_DESKTOP),
      )
    : 1;

  const slicedItems =
    reversedHistoryData?.slice(
      (currentPage - 1) *
        (isMobile ? TOTAL_HISTORY_ITEMS_ON_PAGE_MOBILE : TOTAL_HISTORY_ITEMS_ON_PAGE_DESKTOP),
      currentPage *
        (isMobile ? TOTAL_HISTORY_ITEMS_ON_PAGE_MOBILE : TOTAL_HISTORY_ITEMS_ON_PAGE_DESKTOP),
    ) || [];

  useEffect(() => {
    void dispatch(fetchReferralHistory());
  }, [dispatch]);
  return (
    <S.SectionWrapper $isEmpty={reversedHistoryData?.length === 0}>
      <SVisuallyHidden as="h2">Affiliate program history</SVisuallyHidden>
      {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
        <HistoryTableDesktop items={slicedItems} isLoading={isPending} btcUsdRate={btcUsdRate} />
      ) : (
        <HistoryTableMobile items={slicedItems} isLoading={isPending} btcUsdRate={btcUsdRate} />
      )}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleSetCurrentPage={handleSetCurrentPage}
          t={t}
        />
      )}
    </S.SectionWrapper>
  );
};
