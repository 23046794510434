import { FC } from 'react';
import dayjs from 'dayjs';
import { NO_AFFILIATE_HISTORY } from 'pages/PageAffiliateProgram/constants/noAffiliateHistoryNotification.consts';
import { rowTitles } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import { IReferralHistoryResponseDataMapped } from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from 'pages/PageAffiliateProgram/components/ui/HistoryTableDesktop/HistoryTableDesktop.styled';

interface IHistoryTableDesktopProps {
  items: IReferralHistoryResponseDataMapped[];
  btcUsdRate: number;
  isLoading: boolean;
}

export const HistoryTableDesktop: FC<IHistoryTableDesktopProps> = ({
  items,
  isLoading,
  btcUsdRate,
}) => {
  return (
    <S.TableWrapper>
      {isLoading ? (
        <SSpinnerBlue />
      ) : items.length > 0 ? (
        <S.Table as="table">
          <thead>
            <tr>
              {rowTitles.map(({ title }) => (
                <S.HeadCellTh key={title}>{title}</S.HeadCellTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map(
              ({ referralId, dataTimestamp, coin, purchaseAmount, referralReward }, index) => {
                return (
                  <S.Tr key={index}>
                    <td>{dayjs.utc(dataTimestamp * 1000).format('DD.MM.YYYY')}</td>
                    <td>{referralId}</td>
                    <td>${formatUsdOutput(purchaseAmount * btcUsdRate)}</td>
                    <td>
                      <S.EarnWrapper>
                        <S.UsdValue>${formatUsdOutput(referralReward * btcUsdRate)}</S.UsdValue>
                        <S.BtcValue>
                          {referralReward} {coin}
                        </S.BtcValue>
                      </S.EarnWrapper>
                    </td>
                  </S.Tr>
                );
              },
            )}
          </tbody>
        </S.Table>
      ) : (
        <S.NoAffiliateHistory>{NO_AFFILIATE_HISTORY}</S.NoAffiliateHistory>
      )}
    </S.TableWrapper>
  );
};
