import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';
import { StorageKey } from 'types/storage/storage-key.enum';
import { EProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

import { cutUrl } from 'utils/formatters/cut-url';
import { formatURL } from 'utils/formatters/format-url';
import { AppRoute } from 'utils/route/app-route';

interface IRedirectToOuterRouteProps {
  children: React.ReactNode;
}

const nonPersistingAuthorizedRoutes = [
  AppRoute.Login(),
  AppRoute.Registration(),
  AppRoute.PasswordReset(),
  AppRoute.NotFound(),
];

const nonPersistingUnauthorizedRoutes = [
  AppRoute.AffiliateProgram(),
  AppRoute.Assets(),
  AppRoute.Profile(),
  AppRoute.Payment(),
  AppRoute.ProfileTab(EProfileTabName.Personal),
  AppRoute.ProfileTab(EProfileTabName.Wallet),
  AppRoute.ProfileTab(EProfileTabName.Security),
  AppRoute.ProfileTab(EProfileTabName.Orders),
  AppRoute.Disable2FA(),
  AppRoute.Purchase(),
  AppRoute.TransactionsHistory(),
  AppRoute.NotFound(),
];

const RedirectToOuterRoute: React.FC<IRedirectToOuterRouteProps> = ({ children }) => {
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const outerRoute = localStorage.getItem(StorageKey.OuterRoute);
  const outerRouteWithoutSlash = outerRoute?.replace(/\/$/, '');

  const nonPersistingRoutes =
    userAuthStatus === 'authorized-verified'
      ? nonPersistingAuthorizedRoutes
      : nonPersistingUnauthorizedRoutes;

  const appRoutes = Object.values(AppRoute)
    .map((routeFn) => {
      if (routeFn === AppRoute.ProfileTab) {
        return [
          routeFn(EProfileTabName.Personal),
          routeFn(EProfileTabName.Wallet),
          routeFn(EProfileTabName.Security),
        ];
      }
      return routeFn(EProfileTabName.Personal);
    })
    .flat();

  if (outerRouteWithoutSlash && !appRoutes.includes(outerRouteWithoutSlash)) {
    const path = cutUrl(window.location.href, [...nonPersistingUnauthorizedRoutes, '/r']) || '';
    const newUrl = window.location.href.replace(path, '');
    const route = cutUrl(newUrl) || '';

    if (route.endsWith('/r')) {
      return <Navigate to={AppRoute.Home()} replace />;
    }

    if (appRoutes.includes(route)) {
      return <Navigate to={route} replace />;
    }

    return <Navigate to={AppRoute.NotFound()} replace />;
  }

  if (
    outerRouteWithoutSlash &&
    appRoutes.includes(outerRouteWithoutSlash) &&
    !nonPersistingRoutes.includes(outerRouteWithoutSlash)
  ) {
    return <Navigate to={outerRouteWithoutSlash} replace />;
  }

  return <>{children}</>;
};

export default RedirectToOuterRoute;
