import { EWebimKey } from 'types/webim/webim-key.enum';

export const deleteWebimCookies = (): void => {
  const splitedHostname = window.location.hostname.split('.');
  splitedHostname.shift();
  const hostname = splitedHostname.join('.');

  document.cookie = `${EWebimKey.WEBIM_VISIT_SESSION_ID}=; path=/; Domain=${hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT Secure; SameSite=strict`;
  document.cookie = `${EWebimKey.WEBIM_VISITOR}=; path=/; Domain=${hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT Secure; SameSite=strict`;
};
