import type { FC } from 'react';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { CartEmptyContent } from 'components/logic/Cart/components/ui/CartEmptyContent';
import { CartList } from 'components/logic/Cart/components/ui/CartList';
import { CheckoutButton } from 'components/logic/Cart/components/ui/CheckoutButton';

import * as S from './CartPopupContent.styled';

interface IPropsCartPopupContent {
  isEmpty: boolean;
  isCartPending: boolean;
  cartListWithMinersData: (Omit<ICartItemMapped, 'productId'> & {
    id: number;
  } & IMinerDataMapped)[];
  handleDecrement: (id: number) => void;
  handleIncrement: (id: number, quantity: number, maxQuantity: number) => void;
  handleDeleteAllSameItems: (id: number) => void;
  handleCheckoutClick: () => void;
  setIsTooltipOpen: (value: boolean) => void;
  t: (key: string) => string;
}

export const CartPopupContent: FC<IPropsCartPopupContent> = ({
  isEmpty,
  isCartPending,
  cartListWithMinersData,
  handleDecrement,
  handleIncrement,
  handleDeleteAllSameItems,
  handleCheckoutClick,
  setIsTooltipOpen,
  t,
}): JSX.Element => {
  return (
    <S.CartPopupWrapper>
      {!isEmpty ? (
        <>
          <CartList
            cartListWithMinersData={cartListWithMinersData}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            handleDeleteAllSameItems={handleDeleteAllSameItems}
          />
          <CheckoutButton
            isEmpty={isEmpty}
            isCartPending={isCartPending}
            handleCheckoutClick={handleCheckoutClick}
          />
        </>
      ) : (
        <CartEmptyContent setIsTooltipOpen={setIsTooltipOpen} t={t} />
      )}
    </S.CartPopupWrapper>
  );
};
