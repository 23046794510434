import type { FC } from 'react';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { CartItem } from 'components/logic/Cart/components/ui/CartItem';

import * as S from './CartList.styled';

interface IPropsCartList {
  cartListWithMinersData: (Omit<ICartItemMapped, 'productId'> & {
    id: number;
  } & IMinerDataMapped)[];
  handleDecrement: (id: number) => void;
  handleIncrement: (id: number, quantity: number, maxQuantity: number) => void;
  handleDeleteAllSameItems: (id: number) => void;
}

export const CartList: FC<IPropsCartList> = ({
  cartListWithMinersData,
  handleDecrement,
  handleIncrement,
  handleDeleteAllSameItems,
}): JSX.Element => {
  return (
    <S.CartListItems>
      {cartListWithMinersData.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleDeleteAll={handleDeleteAllSameItems}
        />
      ))}
    </S.CartListItems>
  );
};
