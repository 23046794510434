import type { FC, ReactNode } from 'react';
import { ReactComponent as ShoppingCartDarkIcon } from 'assets/icons/cart/shopping-cart-dark.svg';

import { BuyAMinerButton } from 'components/logic/Cart/components/logic/BuyAMinerButton/BuyAMinerButton';

import * as S from './CartEmptyContent.styled';

interface IPropsCartEmptyContent {
  renderHeader?: ReactNode;
  setIsTooltipOpen: (value: boolean) => void;
  t: (key: string) => string;
}

export const CartEmptyContent: FC<IPropsCartEmptyContent> = ({
  renderHeader,
  t,
  setIsTooltipOpen,
}): JSX.Element => {
  return (
    <S.CartEmptyContentWrapper>
      {renderHeader}
      <S.CartEmptyContent>
        <ShoppingCartDarkIcon width={20} height={20} />
        <S.EmptyText>{t('cart.yourCartIsEmpty')}</S.EmptyText>
      </S.CartEmptyContent>
      <BuyAMinerButton setIsTooltipOpen={setIsTooltipOpen} />
    </S.CartEmptyContentWrapper>
  );
};
