export const ZIndex = {
  Popup: '100',
  Menu: '10',
  Header: '11',
  Slider: '2',
  HeaderTittle: '2',
  DatePickerInput: '2',
  DatePickerTooltip: '3',
  SelectButton: '2',
  SelectMenu: '1',
  Tooltip: '1',
  Cart: '5',
};
