import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { selectWebimShouldUpdate } from 'store/webim-reducer/webim.selectors';

import { RequiredText } from 'components/ui/RequiredText';
import { WebimLink } from 'components/ui/Webim';

import { OuterHeader } from './components/ui/OuterHeader';

import * as S from './OuterLayout.styled';

export const OuterLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { shouldUpdateState } = useSelector(selectWebimShouldUpdate);

  useEffect(() => {
    if (!shouldUpdateState) return;
    window.location.reload();
  }, [shouldUpdateState, dispatch]);

  return (
    <>
      {!shouldUpdateState && (
        <S.Wrapper>
          <S.HeaderWrapper>
            <OuterHeader />
          </S.HeaderWrapper>
          <S.ContentWrapper>
            <S.WebimWrapper>
              <Outlet />
              <WebimLink />
            </S.WebimWrapper>
            <RequiredText />
          </S.ContentWrapper>
        </S.Wrapper>
      )}
    </>
  );
};
