import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IWebimState {
  shouldUpdateState: boolean;
  webimVisitor: { value: { id: string } } | null;
  status: boolean;
}

export const initialState: IWebimState = {
  shouldUpdateState: false,
  webimVisitor: null,
  status: false,
};

const webimSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    shouldUpdateOnceWebimStateAction(state, action: PayloadAction<boolean>) {
      state.shouldUpdateState = action.payload;
    },
    shouldUpdateWebimStateAction(state, action: PayloadAction<boolean>) {
      state.shouldUpdateState = action.payload;
      window.location.reload();
    },

    setWebimStateAction(state, action: PayloadAction<{ value: { id: string } }>) {
      state.webimVisitor = action.payload;
    },

    initWebimStateAction(state) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cuversecom.webim.ru/button.js';
      document.head.appendChild(script);
      state.status = true;
    },
  },
});

export const {
  shouldUpdateWebimStateAction,

  setWebimStateAction,
  shouldUpdateOnceWebimStateAction,
  initWebimStateAction,
} = webimSlice.actions;
export const webimReducer = webimSlice.reducer;
