import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const CartContentWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const CartText = styled(SText)<{ $isEmpty: boolean }>`
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: ${EColors.White};
  user-select: none;
  font-variant-numeric: lining-nums tabular-nums;
  ${({ $isEmpty }) => (!$isEmpty ? 'display: inline-block' : 'display: none;')}
`;

export const CartContent = styled.div<{ $cursorPointer?: boolean }>`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  ${({ $cursorPointer }) => $cursorPointer && 'cursor: pointer;'}

  @media (min-width: ${BreakPoint.MobileTop}) {
    padding: 0 8px;
    border-radius: 8px;
    background: ${EColors.Blue4};
  }
`;
