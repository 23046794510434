import type { FC } from 'react';

import * as S from './Mined.styled';

interface IPropsMined {
  valueBtc: string;
  valueUsd: string;
  icon: React.ReactElement;
  tittleText: string;
}

export const Mined: FC<IPropsMined> = ({ valueBtc, valueUsd, icon, tittleText }): JSX.Element => {
  return (
    <S.MinedWrapper>
      <S.MinedTitle>
        {icon}
        <S.TittleText as="dt">{tittleText}</S.TittleText>
      </S.MinedTitle>
      <S.ValuesWrapper>
        <S.MinedValueUsd as="dd">{valueUsd}</S.MinedValueUsd>
        <S.MinedValueBtc as="dd">{valueBtc}</S.MinedValueBtc>
      </S.ValuesWrapper>
    </S.MinedWrapper>
  );
};
