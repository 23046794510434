import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 24px;
  background-color: ${EColors.White};
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 24px;
  width: 100%;

  & td,
  th {
    max-width: min-content;
    padding-right: 48px;
    text-align: start;
    box-sizing: border-box;

    &:nth-child(4) {
      padding-right: 0;
    }
  }
`;

export const EarnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;
export const UsdValue = styled(SText)`
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 12px;
  line-height: 16px;
`;
export const BtcValue = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const HeadCellTh = styled.th`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: ${EColors.Gray2};
`;

export const Tr = styled.tr`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${EColors.Gray1Text};
`;

export const NoAffiliateHistory = styled(SText)`
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 1.3px;
  text-align: center;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;
