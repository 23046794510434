import React from 'react';
import { ReactComponent as LogoPicture } from 'assets/icons/logo-icons/new-cuverse-logo.svg';

import * as S from './MenuLogo.styled';

export const MenuLogo: React.FC = () => {
  return (
    <S.LogoWrapper>
      <LogoPicture />
    </S.LogoWrapper>
  );
};
