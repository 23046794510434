import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 12px;
  }
`;

export const PaymentDisabledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.BlueGrayBackDark};

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
    border-radius: 16px;
  }
`;

export const DisabledIcon = styled.div`
  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }

  & svg {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    margin: 5px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
`;

export const Text = styled(SText)`
  font-size: 14px;
  line-height: 20px;
`;

export const SupportButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;
  background-color: ${EColors.Blue2NewBrand};
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: ${EColors.White};

  & svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding: 7px 16px;
  }
`;

export const ScreenWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }
`;
