import { useSelector } from 'react-redux';
import { cryptoPaymentLimits } from 'pages/PagePayment/constants/payment-limits';
import { selectDashboardWallet } from 'store/dashboard-reducer/dashboard.selectors';
import { EPaymentMethod, setPaymentsDisabledStatus } from 'store/payment-reducer/payment.reducer';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';

interface IUsePaymentDisableProps {
  paymentAmount: number;
  paymentMethod: EPaymentMethod;
}
interface IUsePaymentDisableReturns {
  getPaymentDisabledStatus: () => boolean;
}

export const usePaymentDisabled = ({
  paymentAmount,
  paymentMethod,
}: IUsePaymentDisableProps): IUsePaymentDisableReturns => {
  const dispatch = useAppDispatch();
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const wallet = useSelector(selectDashboardWallet);

  const paymentDisabledStatus: Record<EPaymentMethod | 'default', () => boolean> = {
    [EPaymentMethod.BtcBalance]: () => {
      const isDisable = paymentAmount > wallet.amount;

      if (isDisable) {
        dispatch(
          setPaymentsDisabledStatus({
            paymentMethod: EPaymentMethod.BtcBalance,
            paymentDisabled: true,
          }),
        );
      }

      return isDisable;
    },
    [EPaymentMethod.AlphaPo]: () => {
      const isDisable = paymentAmount * btcUsdRate > cryptoPaymentLimits[EPaymentMethod.AlphaPo];

      if (isDisable) {
        dispatch(
          setPaymentsDisabledStatus({
            paymentMethod: EPaymentMethod.AlphaPo,
            paymentDisabled: true,
          }),
        );
      }

      return isDisable;
    },
    [EPaymentMethod.CoinsBuy]: () => {
      const isDisable = paymentAmount * btcUsdRate > cryptoPaymentLimits[EPaymentMethod.CoinsBuy];
      return isDisable;
    },
    [EPaymentMethod.Mercuryo]: () => {
      const isDisable = paymentAmount * btcUsdRate > cryptoPaymentLimits[EPaymentMethod.Mercuryo];

      if (isDisable) {
        dispatch(
          setPaymentsDisabledStatus({
            paymentMethod: EPaymentMethod.Mercuryo,
            paymentDisabled: true,
          }),
        );
      }

      return isDisable;
    },
    default: () => false,
  };

  return {
    getPaymentDisabledStatus: () =>
      paymentDisabledStatus[paymentMethod]() || paymentDisabledStatus.default(),
  };
};
