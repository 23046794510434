import type { FC } from 'react';

import * as S from './PaymentOptionBtc.styled';

interface IPropsPaymentOptionBtc {
  t: (key: string) => string;
}

export const PaymentOptionBtc: FC<IPropsPaymentOptionBtc> = ({ t }): JSX.Element => {
  return (
    <S.Option as="dl">
      <S.PaymentTitleWrapper>
        <S.Title as="dt">{t('purchase.paymentOptionText')}</S.Title>
      </S.PaymentTitleWrapper>
      <S.BtcTransferWrapper>
        <S.BtcTitle>{t('payment.payFromBtcBalance')}</S.BtcTitle>
      </S.BtcTransferWrapper>
    </S.Option>
  );
};
