import { FC, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FormApi } from 'final-form';
import { SStyledTabContentWrapper } from 'pages/PageProfile/components/styled/SStyledTabContentWrapper';
import { IUpdateProfileRequestBody } from 'store/api/cuverse-api/profile/types/update-profile-request-body.interface';
import { selectProfile, selectProfileStatus } from 'store/profile-reducer/profile.selectors';
import { updateProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { IPersonalInfoFormValues } from 'types/ui/ProfileTabs/personal-info-form-values.interface';

import { useCartLogic } from 'components/logic/Cart/hooks/useCartLogic';
import { Input } from 'components/ui/Input';
import { TextArea } from 'components/ui/TextArea';
import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { getIsFullVerificationStatus } from 'utils/common/get-is-full-verification-status.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isNotEmptyString } from 'utils/validators/is-not-empty-string';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import * as S from './PersonalInformationForm.styled';

interface IPersonalInfoFormProps {
  t: (key: string) => string;
}

export const PersonalInformationForm: FC<IPersonalInfoFormProps> = ({ t }) => {
  const dispatch = useAppDispatch();
  const { verificationStatusCode } = useSelector(selectProfile);
  const isBasicVerification = getIsBasicVerificationStatus(verificationStatusCode);
  const isFullVerification = getIsFullVerificationStatus(verificationStatusCode);
  const { handleCheckoutClick } = useCartLogic();
  const profile = useSelector(selectProfile);
  const profileFetchStatus = useSelector(selectProfileStatus);
  const isProfileDataPending = profileFetchStatus === 'pending';

  const [address, setAddress] = useState(profile.address);

  const handlePostalCodeChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => form.change('zip', removeNonNumericSymbols(value));

  const handleMailingAddressChange = (
    value: string,
    form: FormApi<IPersonalInfoFormValues, Partial<IPersonalInfoFormValues>>,
  ) => {
    setAddress(value);
    form.change('address', value);
  };

  const handleFormSubmit = (values: IPersonalInfoFormValues) => {
    if (!isProfileDataPending) {
      const { firstname, lastname, country, state, city, zip, address } = values;
      const data: IUpdateProfileRequestBody = {
        firstname,
        lastname,
        country,
        state,
        city,
        zip,
        address1: address,
      };
      void dispatch(
        updateProfileDataThunkAction({
          body: data,
          onSuccessCb: () => {
            handleCheckoutClick();
          },
        }),
      );
    }
  };

  useEffect(() => {
    setAddress(profile.address);
  }, [profile.address]);

  return (
    <SStyledTabContentWrapper>
      <Form onSubmit={handleFormSubmit} initialValues={profile}>
        {({ handleSubmit, submitFailed, hasValidationErrors, form, pristine }) => (
          <S.Form onSubmit={handleSubmit} $isFailed={submitFailed}>
            <S.FormSection>
              <S.PersonalFieldsWrapper>
                <Field
                  name="firstname"
                  validate={composeValidators([isRequired, isNotEmptyString])}
                >
                  {({ input, meta }) => (
                    <S.InputLabel>
                      <S.LabelText $isRequired>{t('profile.firstNameLabel')}</S.LabelText>
                      <Input
                        {...input}
                        isReadOnly={
                          (isBasicVerification || isFullVerification) && !!profile.firstname
                        }
                        type="text"
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.InputLabel>
                  )}
                </Field>

                <Field name="lastname" validate={composeValidators([isRequired, isNotEmptyString])}>
                  {({ input, meta }) => (
                    <S.InputLabel>
                      <S.LabelText $isRequired>{t('profile.lastNameLabel')}</S.LabelText>
                      <Input
                        {...input}
                        isReadOnly={
                          isBasicVerification || (isFullVerification && !!profile.lastname)
                        }
                        type="text"
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.InputLabel>
                  )}
                </Field>
              </S.PersonalFieldsWrapper>
            </S.FormSection>

            <S.FormSection>
              <S.FormSectionTitle>{t('profile.addressTitle')}</S.FormSectionTitle>
              <S.AddressFieldsWrapper>
                <S.HomeAddressWrapper>
                  <Field
                    name="country"
                    validate={composeValidators([isRequired, isNotEmptyString])}
                  >
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText $isRequired>{t('profile.countryLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification && !!profile.country}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="state" validate={composeValidators([isRequired, isNotEmptyString])}>
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText>{t('profile.stateLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification && !!profile.state}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="city" validate={composeValidators([isRequired, isNotEmptyString])}>
                    {({ input, meta }) => (
                      <S.InputLabel>
                        <S.LabelText $isRequired>{t('profile.cityLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification && !!profile.city}
                          type="text"
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.InputLabel>
                    )}
                  </Field>

                  <Field name="zip" validate={composeValidators([isRequired, isNumber])}>
                    {({ input, meta }) => (
                      <S.PostalCodeLabel>
                        <S.LabelText $isRequired>{t('profile.zipCodeLabel')}</S.LabelText>
                        <Input
                          {...input}
                          isReadOnly={isFullVerification && !!profile.zip}
                          type="text"
                          onChange={(evt) => handlePostalCodeChange(evt.currentTarget.value, form)}
                          hasErrors={meta.error && submitFailed}
                        />
                        {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                      </S.PostalCodeLabel>
                    )}
                  </Field>
                </S.HomeAddressWrapper>

                <Field name="address" validate={composeValidators([isRequired, isNotEmptyString])}>
                  {({ input, meta }) => (
                    <S.MailingAddressLabel>
                      <S.LabelText $isRequired>{t('profile.streetAddress')}</S.LabelText>
                      <TextArea
                        {...input}
                        isVerifyPassed={isFullVerification && !!profile.address}
                        textValue={address || ''}
                        handleTextAreaChange={(value) => handleMailingAddressChange(value, form)}
                        hasErrors={meta.error && submitFailed}
                      />
                      {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                    </S.MailingAddressLabel>
                  )}
                </Field>
              </S.AddressFieldsWrapper>
            </S.FormSection>
            <S.FormFooterWrapper>
              <S.SubmitButton
                type="submit"
                text={t('payment.proceedToPayment')}
                onSubmit={handleSubmit}
                disabled={pristine || (submitFailed && hasValidationErrors) || isProfileDataPending}
              />
              <S.RequiredText>{t('global.requiredField')}</S.RequiredText>
            </S.FormFooterWrapper>
          </S.Form>
        )}
      </Form>
    </SStyledTabContentWrapper>
  );
};
