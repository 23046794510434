import type { FC } from 'react';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/dashboard-page-icons/miner-white.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/dashboard-page-icons/timer-thin.svg';

import * as S from './LaunchPending.styled';

interface IPropsLaunchPending {
  launchPendingHashrate: string;
  launchPendingMinersCount: string;
  t: (key: string) => string;
}

export const LaunchPending: FC<IPropsLaunchPending> = ({
  launchPendingHashrate,
  launchPendingMinersCount,
  t,
}): JSX.Element => {
  return (
    <S.LaunchPendingWrapper>
      <TimerIcon />
      <S.Title as="dt">{t('mining.launchPendingTitle')}</S.Title>
      <S.ValuesWrapper>
        <S.HashrateValue as="dd">{launchPendingHashrate}</S.HashrateValue>
        <S.MinersCount>
          <MinerWhiteIcon />
          <S.CountNumber as="dd">{launchPendingMinersCount}</S.CountNumber>
        </S.MinersCount>
      </S.ValuesWrapper>
    </S.LaunchPendingWrapper>
  );
};
