import { EPaymentMethod } from 'store/payment-reducer/payment.reducer';

export const ALPHAPO_PAYMENT_LIMIT = 10000;
export const MERCURIO_PAYMENT_LIMIT = 15000;

export const cryptoPaymentLimits = {
  [EPaymentMethod.AlphaPo]: 150000,
  [EPaymentMethod.Mercuryo]: 15000,
  [EPaymentMethod.CoinsBuy]: Infinity,
  [EPaymentMethod.BtcBalance]: Infinity,
};
