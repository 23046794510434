import React from 'react';
import dayjs from 'dayjs';
import { IHistoryByDates } from 'pages/PageTransactionsHistory/types/history-by-dates.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { TransactionTypeBadge } from 'components/ui/TransactionTypeBadge';

import * as S from './HistoryTableMobile.styled';

interface IMobileTableProps {
  historyByDates: IHistoryByDates[];
  isTransactionHistoryPending: boolean;
  t: (key: string) => string;
}

export const HistoryTableMobile: React.FC<IMobileTableProps> = ({
  historyByDates,
  isTransactionHistoryPending,
  t,
}) => {
  return (
    <S.DatesContainer>
      {isTransactionHistoryPending ? (
        <SSpinnerBlue />
      ) : (
        historyByDates.map(({ date, historyItems }) => (
          <S.HistoryDay key={date}>
            <S.DateRow as="time" dateTime={new Date(date).toISOString()}>
              {dayjs(date).format('MMMM DD, YYYY')}
            </S.DateRow>
            {historyItems.map(({ id, category, title, datetime }) => (
              <S.TransactionItem as="dl" key={id}>
                <S.TransactionType as="dt">
                  <TransactionTypeBadge t={t} transactionType={category} />
                </S.TransactionType>
                <S.TransactionDate as="dd">
                  <time dateTime={new Date(date).toISOString()}>
                    {dayjs.utc(datetime).format('HH:mm')}
                  </time>
                </S.TransactionDate>
                <S.TransactionId as="dd">
                  {t('transactionHistory.reference')} {id}
                </S.TransactionId>
                <S.TransactionMessage as="dd">{`${title}`}</S.TransactionMessage>
              </S.TransactionItem>
            ))}
          </S.HistoryDay>
        ))
      )}
    </S.DatesContainer>
  );
};
