import React from 'react';
import { ReactComponent as MinerIcon } from 'assets/icons/button-icons/miner.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/dashboard-page-icons/calendar-blank.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/dashboard-page-icons/clock.svg';
import { ReactComponent as MinerBlack } from 'assets/icons/dashboard-page-icons/miner-black.svg';
import { SArticleTitle } from 'pages/PageDashboard/components/styled';

import { Button } from 'components/ui/Button';

import { HashrateAmount } from './components/ui/HashrateAmount';
import { LaunchPending } from './components/ui/LaunchPending';
import { Mined } from './components/ui/Mined';

import * as S from './MiningArticle.styled';

interface IMiningArticleProps {
  hashrate: string;
  activeMinersCount: string;
  launchPendingHashrate: string;
  launchPendingMinersCount: string;
  minedYesterdayBtc: string;
  minedYesterdayUsd: string;
  totalMinedBtc: string;
  totalMinedUsd: string;
  t: (key: string) => string;
  isEnabled: boolean;
  openLimitedUptimePopup: () => void;
}

export const MiningArticle: React.FC<IMiningArticleProps> = ({
  hashrate,
  activeMinersCount,
  launchPendingMinersCount,
  launchPendingHashrate,
  minedYesterdayBtc,
  minedYesterdayUsd,
  totalMinedBtc,
  totalMinedUsd,
  t,
  isEnabled,
  openLimitedUptimePopup,
}) => {
  return (
    <S.MiningArticle as="article">
      <S.ArticleTittleWrapper>
        <SArticleTitle as="h3">
          <MinerBlack />
          {t('mining.sectionTitle')}
        </SArticleTitle>
        <Button
          className="article-button"
          variant={'primary-gray'}
          text={isEnabled ? t('uptime.65uptime') : t('uptime.100uptime')}
          icon={<MinerIcon />}
          onClick={openLimitedUptimePopup}
        />
      </S.ArticleTittleWrapper>
      <S.HashrateList as="dl">
        <S.Top>
          <HashrateAmount hashrate={hashrate} activeMinersCount={activeMinersCount} t={t} />
          <LaunchPending
            launchPendingHashrate={launchPendingHashrate}
            launchPendingMinersCount={launchPendingMinersCount}
            t={t}
          />
        </S.Top>
        <S.Bottom>
          <Mined
            valueBtc={minedYesterdayBtc}
            valueUsd={minedYesterdayUsd}
            icon={<ClockIcon />}
            tittleText={t('mining.minedYesterdayTitle')}
          />
          <Mined
            valueBtc={totalMinedBtc}
            valueUsd={totalMinedUsd}
            icon={<CalendarIcon />}
            tittleText={t('mining.totalMinedTitle')}
          />
        </S.Bottom>
      </S.HashrateList>
    </S.MiningArticle>
  );
};
