import { IReferralHistoryResponseDataMapped } from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';

type RowKey = keyof IReferralHistoryResponseDataMapped;

interface IRowTitle {
  title: string;
  key: RowKey;
  isDate?: boolean;
  name?: string;
}

export const rowTitles: IRowTitle[] = [
  { title: 'DATE', key: 'dataTimestamp', isDate: true },
  { title: 'USER ID', key: 'referralId' },
  { title: 'PURCHASE AMOUNT', key: 'purchaseAmount', name: 'BTC' },
  { title: 'YOU EARNED', key: 'referralReward', name: 'BTC' },
];

export const TOTAL_HISTORY_ITEMS_ON_PAGE_MOBILE = 5;
export const TOTAL_HISTORY_ITEMS_ON_PAGE_DESKTOP = 15;
