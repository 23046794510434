import type { FC } from 'react';
import { ReactComponent as XIcon } from 'assets/icons/cart/x.svg';

import { ClearCartText } from 'components/logic/Cart/components/ui/ClearCartText/ClearCartText';

import * as S from './CartHeader.styled';

interface IPropsCartHeader {
  isEmpty: boolean;
  handleDeleteAllItems: () => void;
  handleTooltipClose: () => void;
  t: (key: string) => string;
}

export const CartHeader: FC<IPropsCartHeader> = ({
  isEmpty,
  handleDeleteAllItems,
  handleTooltipClose,
  t,
}): JSX.Element => {
  return (
    <S.ClearAllTextWrapper>
      {!isEmpty ? (
        <ClearCartText t={t} handleDeleteAllItems={handleDeleteAllItems} />
      ) : (
        <S.CartText>{t('cart.cart')}</S.CartText>
      )}
      <XIcon onClick={handleTooltipClose} />
    </S.ClearAllTextWrapper>
  );
};
