import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isShowLanguageSwitcher } from 'constants/i18next/i18next.consts';
import { useCloseByClick } from 'hooks/useCloseByClick';
import { useCloseByEsc } from 'hooks/useCloseByEsc';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { useReplaceUrl } from 'hooks/useReplaceUrl';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useWebImButton } from 'hooks/useWebimButton';
import { DepositBtcContent } from 'pages/PageDashboard/components/logic/DepositBtcContent';
import { DepositElectricityForm } from 'pages/PageDashboard/components/logic/DepositElectricityForm';
import { WithdrawContent } from 'pages/PageDashboard/components/logic/WithdrawContent';
import { useChangeWalletPopupAttributes } from 'pages/PageDashboard/hooks/useChangeWalletPopupAttributes';
import { useWithdrawPopupTitle } from 'pages/PageDashboard/hooks/useWithdrawPopupTitle';
import { Enable2FAContent } from 'pages/PageProfile/components/logic/Enable2FAContent';
import { AdditionalTerms } from 'pages/PagePurchase/components/logic/AdditionalTerms';
import { ElectricityCashbackTerms } from 'pages/PagePurchase/components/ui/ElectricityCashbackTerms/ElectricityCashbackTerms';
import { EPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { selectLogoutFetchStatus } from 'store/auth-reducer/auth.selectors';
import { requestLogoutThunkAction } from 'store/auth-reducer/auth.thunk-actions';
import { fetchCartThunkAction } from 'store/cart-reducer/cart.thunk-actions';
import { setDepositStepAction } from 'store/deposit-reducer/deposit.reducer';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import {
  selectIsAdditionalTermsPopupOpened,
  selectIsCartContentPopupOpened,
  selectIsChangeWalletPopupOpened,
  selectIsDepositBtcPopupOpened,
  selectIsDepositElectricityPopupOpened,
  selectIsElectricityCashbackTermsPopupOpened,
  selectIsEnable2FAPopupOpened,
  selectIsLimitedUptimeExplainerPopupOpened,
  selectIsLimitedUptimeExplainerSuccessPopupOpened,
  selectIsWithdrawBtcPopupOpened,
} from 'store/modals-reducer/modals.selectors';
import { setWalletChangeStatusAction } from 'store/profile-reducer/profile.reducer';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';
import { selectPurchaseStep } from 'store/purchase-reducer/purchase.selectors';
import { fetchActiveMinersThunkAction } from 'store/purchase-reducer/purchase.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { CartPopupContent } from 'components/logic/Cart/components/logic/CartPopupContent';
import { ClearCartText } from 'components/logic/Cart/components/ui/ClearCartText';
import { useCartLogic } from 'components/logic/Cart/hooks/useCartLogic';
import { LimitedUptimeExplainer } from 'components/logic/LimitedUptimeExplainer';
import { SuccessUptimeExplainer } from 'components/logic/SuccessUptimeExplainer';
import { Popup } from 'components/ui/Popup';
import { formatURL } from 'utils/formatters/format-url';

import { InnerHeader } from './components/ui/InnerHeader';
import { LanguageSwitcher } from './components/ui/LanguageSwitcher';
import { MainMenu } from './components/ui/MainMenu';

import * as S from './InnerLayout.styled';

export const InnerLayout: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useReplaceUrl();
  const dispatch = useAppDispatch();
  const purchaseStep = useSelector(selectPurchaseStep);

  const logoutFetchStatus = useSelector(selectLogoutFetchStatus);
  const isEnable2FAPopupOpened = useSelector(selectIsEnable2FAPopupOpened);
  const isDepositBtcPopupOpened = useSelector(selectIsDepositBtcPopupOpened);
  const isWithdrawBtcPopupOpened = useSelector(selectIsWithdrawBtcPopupOpened);
  const isDepositElectricityPopupOpened = useSelector(selectIsDepositElectricityPopupOpened);
  const isChangeWalletPopupOpened = useSelector(selectIsChangeWalletPopupOpened);
  const isAdditionalTermsPopupOpened = useSelector(selectIsAdditionalTermsPopupOpened);
  const isCartContentOpened = useSelector(selectIsCartContentPopupOpened);
  const isElectricityCashbackTermsPopupOpened = useSelector(
    selectIsElectricityCashbackTermsPopupOpened,
  );
  const isLimitedUptimePopupOpened = useSelector(selectIsLimitedUptimeExplainerPopupOpened);
  const isLimitedUptimeSuccessPopupOpened = useSelector(
    selectIsLimitedUptimeExplainerSuccessPopupOpened,
  );

  const { innerWidth } = useInnerWidth();
  const sideSpace = (innerWidth - parseInt(BreakPoint.DesktopTop)) / 2;

  const [isMainMenuOpened, setIsMainMenuOpened] = useState(false);

  const { withdrawPopupTitle } = useWithdrawPopupTitle();
  const { walletAddressPopupTitle, walletAddressPopupContent } = useChangeWalletPopupAttributes();

  const handleMainMenuOpen = (): void => {
    setIsMainMenuOpened(true);
  };

  const handleMainMenuClose = (): void => {
    setIsMainMenuOpened(false);
  };

  const handleLogout = (): void => {
    void dispatch(requestLogoutThunkAction());
  };

  const handleEnable2FAPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleDepositBtcPopupClose = (): void => {
    dispatch(setDepositStepAction('initial'));
    dispatch(closePopupAction());
  };

  const handleWithdrawBtcPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleDepositElectricityPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleChangeWalletPopupClose = (): void => {
    dispatch(setWalletChangeStatusAction('not-confirmed'));
    dispatch(closePopupAction());
  };

  const handleAdditionalTermsPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleElectricityCashbackTermsPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleResetBuyingFlow = (): void => {
    if (purchaseStep !== EPurchaseStep.Buying) {
      dispatch(setPurchaseStepAction(EPurchaseStep.Buying));
    }
  };

  const handleLimitedUptimePopupClose = (): void => {
    dispatch(closePopupAction());
  };

  const handleCartContentPopupClose = (): void => {
    dispatch(closePopupAction());
  };

  useCloseByEsc({ isShown: isMainMenuOpened, cb: handleMainMenuClose });
  useCloseByClick({ isShown: isMainMenuOpened, cb: handleMainMenuClose });
  useScrollToTop(pathname);

  useWebImButton();
  const {
    isEmpty: isCartEmpty,
    handleDeleteAllItems,
    handleDeleteAllSameItems,
    handleDecrement,
    handleIncrement,
    handleCheckoutClick,
    isCartPending,
    cartListWithMinersData,
    setIsTooltipOpen,
  } = useCartLogic();

  useEffect(() => {
    void dispatch(fetchActiveMinersThunkAction());
    void dispatch(fetchCartThunkAction());
  }, [dispatch]);

  return (
    <S.Wrapper>
      <S.HeaderWrapper $sideSpace={sideSpace >= 0 ? sideSpace : 0}>
        <InnerHeader isMainMenuOpened={isMainMenuOpened} handleMainMenuOpen={handleMainMenuOpen} />
        {isShowLanguageSwitcher && (
          <S.LngSwitcherMobile>
            <LanguageSwitcher />
          </S.LngSwitcherMobile>
        )}
      </S.HeaderWrapper>

      <S.MenuWrapper
        $sideSpace={sideSpace >= 0 ? sideSpace : 0}
        style={{
          display:
            innerWidth >= parseInt(BreakPoint.DesktopLow) || isMainMenuOpened ? 'flex' : 'none',
        }}
      >
        <MainMenu
          handleLogout={handleLogout}
          isLoggingOut={logoutFetchStatus === 'pending'}
          handleResetBuyingFlow={handleResetBuyingFlow}
          isMainMenuOpened={isMainMenuOpened}
        />
      </S.MenuWrapper>

      <S.ContentWrapper $sideSpace={sideSpace >= 0 ? sideSpace : 0}>
        <Outlet />
      </S.ContentWrapper>
      {isEnable2FAPopupOpened && (
        <Popup
          title={t('popup.googleAuthTitle')}
          content={<Enable2FAContent />}
          handlePopupClose={handleEnable2FAPopupClose}
        />
      )}
      {isDepositBtcPopupOpened && (
        <Popup
          title={t('popup.depositBtcTitle')}
          content={<DepositBtcContent t={t} />}
          handlePopupClose={handleDepositBtcPopupClose}
        />
      )}
      {isWithdrawBtcPopupOpened && (
        <Popup
          title={withdrawPopupTitle}
          content={<WithdrawContent />}
          handlePopupClose={handleWithdrawBtcPopupClose}
        />
      )}
      {isDepositElectricityPopupOpened && (
        <Popup
          title={t('popup.depositElectricityTitle')}
          content={<DepositElectricityForm t={t} />}
          handlePopupClose={handleDepositElectricityPopupClose}
          variant="wide"
        />
      )}
      {isChangeWalletPopupOpened && (
        <Popup
          title={walletAddressPopupTitle}
          content={walletAddressPopupContent}
          handlePopupClose={handleChangeWalletPopupClose}
        />
      )}
      {isAdditionalTermsPopupOpened && (
        <Popup
          title={t('popup.additionalTerms')}
          content={<AdditionalTerms />}
          variant="wide"
          handlePopupClose={handleAdditionalTermsPopupClose}
        />
      )}
      {isElectricityCashbackTermsPopupOpened && (
        <Popup
          title={t('popup.termsAndConditions')}
          content={<ElectricityCashbackTerms t={t} />}
          variant="wide"
          handlePopupClose={handleElectricityCashbackTermsPopupClose}
        />
      )}
      {isLimitedUptimePopupOpened && (
        <Popup
          title={
            pathname.endsWith('/assets')
              ? t('uptime.how65PercentUptimeWorks')
              : t('uptime.uptimeSettings')
          }
          tittleSize={pathname.endsWith('/assets') ? '18px' : '24px'}
          TittleLineHeight={pathname.endsWith('/assets') ? '32px' : undefined}
          content={<LimitedUptimeExplainer isInAssets={pathname.endsWith('/assets')} />}
          handlePopupClose={handleLimitedUptimePopupClose}
        />
      )}
      {isCartContentOpened && (
        <Popup
          title={
            isCartEmpty ? (
              t('cart.cart')
            ) : (
              <ClearCartText t={t} handleDeleteAllItems={handleDeleteAllItems} />
            )
          }
          content={
            <CartPopupContent
              isEmpty={isCartEmpty}
              cartListWithMinersData={cartListWithMinersData}
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              handleDeleteAllSameItems={handleDeleteAllSameItems}
              handleCheckoutClick={handleCheckoutClick}
              setIsTooltipOpen={setIsTooltipOpen}
              isCartPending={isCartPending}
              t={t}
            />
          }
          handlePopupClose={handleCartContentPopupClose}
        />
      )}
      {isLimitedUptimeSuccessPopupOpened && (
        <Popup
          title={t('uptime.switchingUptimeMode')}
          tittleSize={innerWidth <= parseInt(BreakPoint.MobileTop) ? '18px' : '24px'}
          TittleLineHeight={innerWidth <= parseInt(BreakPoint.MobileTop) ? '24px' : '32px'}
          variant="wide"
          content={<SuccessUptimeExplainer />}
          handlePopupClose={handleLimitedUptimePopupClose}
        />
      )}
    </S.Wrapper>
  );
};
