import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockDarkIcon } from 'assets/icons/uptime-icons/clock-big-gray.svg';
import { useUptime } from 'hooks/useUptime';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';

import * as S from './SuccessUptimeExplainer.styled';

export const SuccessUptimeExplainer: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { uptime, uptimeStatus, handleLimitedUptimeDisabled, handleLimitedUptimeEnabled } =
    useUptime();

  const handleChange = () => {
    if (uptime) {
      void handleLimitedUptimeDisabled();
      dispatch(closePopupAction());
    } else {
      void handleLimitedUptimeEnabled();
      dispatch(closePopupAction());
    }
  };

  const handleCancel = () => {
    dispatch(closePopupAction());
  };

  return (
    <S.SuccessUptimeExplainer>
      <S.ContentWrapper>
        {uptime ? (
          <S.Tittle>{t('uptime.switchUptimeModeConfirmation100')}</S.Tittle>
        ) : (
          <S.Tittle>{t('uptime.switchUptimeModeConfirmation65')}</S.Tittle>
        )}
        <S.ModeRestriction>
          <ClockDarkIcon />
          <S.RestrictionText>{t('uptime.uptimeModeRestriction')}</S.RestrictionText>
        </S.ModeRestriction>
      </S.ContentWrapper>
      <S.ButtonsWrapper>
        <S.SwitchModeButton
          text={t('uptime.switchMode')}
          onClick={handleChange}
          disabled={uptimeStatus === 'pending'}
        />
        <S.CancelButton
          text={t('uptime.cancel')}
          onClick={handleCancel}
          disabled={uptimeStatus === 'pending'}
          variant="bordered"
        />
      </S.ButtonsWrapper>
    </S.SuccessUptimeExplainer>
  );
};
