import { SInfoArticle, SInfoBadge } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const MiningArticle = styled(SInfoArticle)`
  flex: 1 1 610px;
  gap: 16px;
  background-color: ${EColors.White};

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-basis: 342px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: flex-start;
    flex-basis: auto;
    padding: 12px;
    border-radius: 16px;
  }
`;

export const ArticleTittleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .article-button {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

export const HashrateList = styled(SInfoBadge)`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 12px;
    padding: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;

  @media (max-width: ${BreakPoint.TabletTop}) {
    justify-content: inherit;
    align-self: stretch;
    gap: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 12px;
  }
`;
export const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 48px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;
