import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const SInfoBadge = styled.dl`
  display: flex;
  border-radius: 16px;
  background-color: ${EColors.BlueGrayBack};

  @media (max-width: ${BreakPoint.MobileTop}) {
    background-color: ${EColors.White};
  }
`;
