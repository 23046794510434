import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchCartThunkAction } from 'store/cart-reducer/cart.thunk-actions';
import { selectDashboardWallet } from 'store/dashboard-reducer/dashboard.selectors';
import { selectDisabledAllPaymentMethodsStatus } from 'store/payment-reducer/payment.selectors';
import { useAppDispatch } from 'store/store';

import { SPage } from 'components/styled/SPage';

import { PaymentOptionsHeader } from './components/ui/PaymentOptionsHeader';
import { CHOOSE_PAYMENT_OPTION } from './components/ui/PaymentOptionsHeader/constants/payment-options-header.consts';
import { PaymentOptionsScreen } from './components/ui/PaymentOptionsScreen';
import { usePayment } from './hooks/usePayment';

export const PagePayment: FC = () => {
  const dispatch = useAppDispatch();
  const { amount: balance } = useSelector(selectDashboardWallet);
  const { route, amount, paymentId, paymentCategory, handlePaymentButtonClick } = usePayment();
  const disabledAllPaymentMethodsStatus = useSelector(selectDisabledAllPaymentMethodsStatus);
  const { t } = useTranslation();

  useEffect(() => {
    void dispatch(fetchCartThunkAction());
  }, [dispatch]);

  return (
    <SPage
      headerComponent={<PaymentOptionsHeader paymentCategory={paymentCategory} />}
      headerAlignItems="left"
      pageTittle={CHOOSE_PAYMENT_OPTION}
    >
      {!paymentId ? (
        <Navigate to={route} replace={true} />
      ) : (
        <PaymentOptionsScreen
          balance={balance}
          amount={amount}
          handlePaymentButtonClick={handlePaymentButtonClick}
          disabledAllPaymentMethodsStatus={disabledAllPaymentMethodsStatus}
          t={t}
        />
      )}
    </SPage>
  );
};
