import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BnbIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/bnb.svg';
import { ReactComponent as EthIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/eth.svg';
import { ReactComponent as UsdcIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdc.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdt.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/payment-options-icon/wallet.svg';
import { ALPHAPO_PAYMENT_LIMIT } from 'pages/PagePayment/constants/payment-limits';
import { usePaymentDisabled } from 'pages/PagePayment/hooks/usePaymentDisabled';
import { useUnloadStatus } from 'pages/PagePayment/hooks/useUnloadStatus';
import {
  ECryptoPaymentVariant,
  EPaymentMethod,
  EPaymentType,
} from 'store/payment-reducer/payment.reducer';
import { selectPaymentCryptoFetchStatus } from 'store/payment-reducer/payment.selectors';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';

import { SWarningText } from 'components/styled/SWarningText/SWarningText';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface CryptoPaymentProps {
  t: (key: string) => string;
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
  amount: number;
}

export const CryptoPaymentCard: React.FC<CryptoPaymentProps> = ({
  handlePaymentButtonClick,
  t,
  amount,
}) => {
  const [isDisable, setIsDisable] = React.useState(false);

  const paymentCryptoStatus = useSelector(selectPaymentCryptoFetchStatus);
  const { isLoading } = useUnloadStatus(paymentCryptoStatus);
  const { getPaymentDisabledStatus } = usePaymentDisabled({
    paymentAmount: amount,
    paymentMethod: EPaymentMethod.AlphaPo,
  });

  useEffect(() => {
    setIsDisable(getPaymentDisabledStatus());
  }, [getPaymentDisabledStatus]);

  return (
    <S.CardWrapper>
      <S.CardContent>
        <S.CardTitleWrapper>
          <WalletIcon />
          <S.CartTitle>{t('payment.crypto')}</S.CartTitle>
        </S.CardTitleWrapper>
        <S.CardText>{t('payment.cryptoPaymentInfoText')}</S.CardText>
        <S.IconsContainer $iconGap={8}>
          <UsdtIcon />
          <UsdcIcon />
          <EthIcon />
          <BnbIcon />
          <S.IconText>{t('payment.andManyMore')}</S.IconText>
        </S.IconsContainer>
      </S.CardContent>
      {isLoading ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.PayButtonWrapper>
          <SWarningText $isDisabled={isDisable}>{t('payment.maxPaymentAlphaPo')}</SWarningText>
          <S.ButtonsWrapper>
            <S.PayButton
              text={t('payment.cryptoPaymentTitle')}
              disabled={isDisable}
              onClick={() =>
                handlePaymentButtonClick(EPaymentType.Crypto, ECryptoPaymentVariant.AlphaPo)
              }
            />
            {/* <S.PayButton
              text={t('payment.cryptoPaymentCoinsBuy')}
              onClick={() =>
                handlePaymentButtonClick(EPaymentType.Crypto, ECryptoPaymentVariant.CoinsBuy)
              }
            /> */}
          </S.ButtonsWrapper>
        </S.PayButtonWrapper>
      )}
    </S.CardWrapper>
  );
};
