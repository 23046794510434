import { styled } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const ClearAllTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > :nth-child(2) {
    cursor: pointer;
  }
`;

export const CartText = styled(SText)`
  font-variant-numeric: lining-nums tabular-nums;
  line-height: 20px;
  text-transform: capitalize;
`;
