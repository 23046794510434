import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BitcoinIcon } from 'assets/icons/payment-options-icon/bitcoin-icon.svg';
import { usePaymentDisabled } from 'pages/PagePayment/hooks/usePaymentDisabled';
import { EPaymentMethod, EPaymentType } from 'store/payment-reducer/payment.reducer';
import { selectBtcBalanceFetchStatus } from 'store/payment-reducer/payment.selectors';

import { SWarningText } from 'components/styled/SWarningText/SWarningText';
import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface PaymentOptionBtcProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  balance: number;
  amount: number;
  t: (key: string) => string;
}

export const BtcPaymentCard: React.FC<PaymentOptionBtcProps> = (props) => {
  const { handlePaymentButtonClick, balance, amount, t } = props;
  const paymentStatus = useSelector(selectBtcBalanceFetchStatus);
  const [isDisable, setIsDisable] = React.useState(false);

  const { getPaymentDisabledStatus } = usePaymentDisabled({
    paymentAmount: amount,
    paymentMethod: EPaymentMethod.BtcBalance,
  });

  useEffect(() => {
    setIsDisable(getPaymentDisabledStatus());
  }, [getPaymentDisabledStatus]);
  const unit = 'BTC';
  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        <BitcoinIcon />
        <S.CartTitle>{t('payment.btcBalance')}</S.CartTitle>
      </S.CardTitleWrapper>
      <S.AmountInfo>
        <S.AmountContainer>
          <S.AmountTitle>{t('payment.accountBalance')}</S.AmountTitle>
          <S.Amount>
            {balance} {unit}
          </S.Amount>
        </S.AmountContainer>
        <S.AmountContainer>
          <S.AmountTitle>{t('payment.amountDue')}</S.AmountTitle>
          <S.AmountYouPay>
            {formatBtcOutput(amount)} {unit}
          </S.AmountYouPay>
        </S.AmountContainer>
      </S.AmountInfo>

      {paymentStatus === 'pending' ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.PayButtonWrapper>
          {isDisable && <SWarningText $isDisabled>{t('payment.lowBalance')}</SWarningText>}
          <S.PayButton
            text={t('payment.payFromBtcBalance')}
            disabled={isDisable}
            onClick={() => handlePaymentButtonClick(EPaymentType.Balance)}
          />
        </S.PayButtonWrapper>
      )}
    </S.CardWrapper>
  );
};
