import { FC } from 'react';
import dayjs from 'dayjs';
import { NO_AFFILIATE_HISTORY } from 'pages/PageAffiliateProgram/constants/noAffiliateHistoryNotification.consts';
import { rowTitles } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import { IReferralHistoryResponseDataMapped } from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from 'pages/PageAffiliateProgram/components/ui/HistoryTableMobile/HistoryTableMobile.styled';

interface IHistoryTableMobileProps {
  items: IReferralHistoryResponseDataMapped[];
  btcUsdRate: number;
  isLoading: boolean;
}

export const HistoryTableMobile: FC<IHistoryTableMobileProps> = ({
  items,
  isLoading,
  btcUsdRate,
}) => {
  return (
    <S.TableWrapper>
      {isLoading ? (
        <SSpinnerBlue />
      ) : items.length > 0 ? (
        <ul>
          {items.map(
            ({ referralId, dataTimestamp, coin, purchaseAmount, referralReward }, index) => (
              <S.Li key={index}>
                <S.Row>
                  <S.Title>{rowTitles[0].title}</S.Title>
                  <S.Value>{dayjs.utc(dataTimestamp * 1000).format('DD.MM.YYYY')}</S.Value>
                </S.Row>
                <S.Row>
                  <S.Title>{rowTitles[1].title}</S.Title>
                  <S.Value>{referralId}</S.Value>
                </S.Row>
                <S.Row>
                  <S.Title>{rowTitles[2].title}</S.Title>
                  <S.Value>${formatUsdOutput(purchaseAmount * btcUsdRate)}</S.Value>
                </S.Row>
                <S.Row>
                  <S.Title>{rowTitles[3].title}</S.Title>
                  <S.ValuesWrapper>
                    <S.Value>${formatUsdOutput(referralReward * btcUsdRate)}</S.Value>
                    <S.ValueBtc>
                      {referralReward} {coin}
                    </S.ValueBtc>
                  </S.ValuesWrapper>
                </S.Row>
              </S.Li>
            ),
          )}
        </ul>
      ) : (
        <S.NoAffiliateHistory>{NO_AFFILIATE_HISTORY}</S.NoAffiliateHistory>
      )}
    </S.TableWrapper>
  );
};
