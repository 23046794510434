import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const CodeVerificationSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color: #000;
`;

export const BackButton = styled(Button)`
  align-self: flex-start;
  gap: 4px;
`;

export const Form = styled.form<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: center;
  }
`;

export const Label = styled.label`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &::after {
    content: '*';
    padding-left: 4px;
    font-weight: 900;
    color: #ec494f;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CodeInput = styled(Input)`
  max-width: 48px;

  & input {
    border-radius: 12px;
    text-align: center;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 40px;

    & input {
      border-radius: 4px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
  }
`;

export const CancelButton = styled(SubmitButton)`
  padding: 11px 15px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 6px 11px;
  }
`;
