import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Button } from 'components/ui/Button';

export const CartCheckoutButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (min-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding: 7px 12px;
  }
`;
