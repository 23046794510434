import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

import { TransactionTypeBadge } from 'components/ui/TransactionTypeBadge';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 24px;
  border-radius: 0 0 24px 24px;
  background-color: #fff;
`;

export const Table = styled.table`
  & td,
  th {
    text-align: start;

    &:nth-child(1) {
      min-width: 150px;
    }

    &:nth-of-type(3) {
      width: 100%;
    }
  }
`;

export const HeadCell = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-bottom: 16px;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #646464;
  justify-self: flex-start;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const DateRow = styled.time`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #272727;
`;

export const ValueCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  min-width: 100%;
  padding-right: 16px;
  padding-bottom: 24px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const TimeCell = styled(ValueCell)`
  padding-right: 0;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const TransactionType = styled(TransactionTypeBadge)`
  width: 100%;
`;
