import { createSlice } from '@reduxjs/toolkit';
import { ICartDataMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
  createCartThunkAction,
  deleteAllCartItemsThunkAction,
  deleteCartItemThunkAction,
  fetchCartThunkAction,
  multipleUpdateCartThunkAction,
  updateAllCartThunkAction,
  updateCartThunkAction,
} from './cart.thunk-actions';

export interface ICartState {
  cart: ICartDataMapped;
  cartListFetchStatus: TFetchStatus;
  cartError: string | null;
}

export const initialState: ICartState = {
  cart: { cartId: 0, totalCosts: 0, totalCostPrice: 0, cartItems: [] },
  cartListFetchStatus: 'initial',
  cartError: null,
};

const cartSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    clearCartAction: (state) => {
      state.cart = { cartId: 0, totalCosts: 0, totalCostPrice: 0, cartItems: [] };
    },
    clearCartItems: (state) => {
      state.cart = { ...state.cart, cartItems: [] };
    },
    clearCartItemsAction: (state) => {
      state.cart.cartItems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(fetchCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(fetchCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cart = { cartId: 0, totalCosts: 0, totalCostPrice: 0, cartItems: [] };
      })
      .addCase(createCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(createCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(createCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(updateCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(updateCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(updateCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(updateAllCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(updateAllCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(updateAllCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(multipleUpdateCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(multipleUpdateCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(multipleUpdateCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })

      .addCase(deleteCartItemThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(deleteCartItemThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(deleteCartItemThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(deleteAllCartItemsThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(deleteAllCartItemsThunkAction.fulfilled, (state) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = { cartId: 0, totalCosts: 0, totalCostPrice: 0, cartItems: [] };
      })
      .addCase(deleteAllCartItemsThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      });
  },
});

export const cartReducer = cartSlice.reducer;

export const { clearCartAction, clearCartItemsAction, clearCartItems } = cartSlice.actions;
