import type { FC } from 'react';
import { ReactComponent as XCircleRedIcon } from 'assets/icons/button-icons/x-circle-red.svg';

import * as S from './ClearCartText.styled';

interface IPropsClearCartText {
  t: (key: string) => string;
  handleDeleteAllItems: () => void;
}

export const ClearCartText: FC<IPropsClearCartText> = ({
  t,
  handleDeleteAllItems,
}): JSX.Element => {
  return (
    <S.ClearAllText onClick={handleDeleteAllItems}>
      <XCircleRedIcon />
      {t('cart.clearCartText')}
    </S.ClearAllText>
  );
};
