import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const ConfirmationSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color: #000;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsDescription = styled(SText)`
  font-size: 14px;
  line-height: 18px;
`;

export const ConfirmButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
  }
`;

export const CancelButton = styled(ConfirmButton)`
  padding: 11px 15px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 6px 11px;
  }
`;
