import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: ${EColors.White};
`;

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-bottom: 1px solid rgba(161 161 161 / 30%);

  &:last-child {
    border-bottom: none;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
`;

export const Title = styled(SText)`
  width: 96px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Value = styled(SText)`
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 12px;
  line-height: 16px;
`;
export const ValueBtc = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const NoAffiliateHistory = styled(SText)`
  padding: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-align: center;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px;
  }
`;
