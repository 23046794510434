import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const CartEmptyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 212px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
  }
`;

export const CartEmptyContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const EmptyText = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;
