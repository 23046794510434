import React from 'react';
import { ReactComponent as Lightning } from 'assets/icons/dashboard-page-icons/lightning.svg';
import { ReactComponent as Plus } from 'assets/icons/dashboard-page-icons/plus.svg';

import * as S from './ElectricityArticle.styled';

interface IElectricityArticleProps {
  paymentBalanceUsd: string;
  paymentUsdDay: string;
  dailyPaymentUsd: string;
  paidDays: string;
  handleReplenishClick: () => void;
  t: (key: string) => string;
}

export const ElectricityArticle: React.FC<IElectricityArticleProps> = ({
  paymentBalanceUsd,
  paymentUsdDay,
  dailyPaymentUsd,
  paidDays,
  handleReplenishClick,
  t,
}) => {
  return (
    <S.ElectricityArticle as="dl">
      <S.BalanceTitle as="dt">
        <Lightning />
        {t('wallet.electricityPaymentBalance')}
      </S.BalanceTitle>
      <S.BalanceValue as="dd">{paymentBalanceUsd}</S.BalanceValue>
      <S.ReplenishButton
        text={t('wallet.deposit')}
        icon={<Plus />}
        onClick={handleReplenishClick}
      />
      <S.Separator />
      <S.PaymentTitle>{t('wallet.electricityDailyPaymentTitle')}</S.PaymentTitle>
      <S.PaymentValue>{dailyPaymentUsd}</S.PaymentValue>
      <S.SufficientTitle>{t('wallet.electricitySufficientForTitle')}</S.SufficientTitle>
      <S.SufficientValue>{paidDays}</S.SufficientValue>
      <S.SufficientValueUsdDay>{paymentUsdDay}</S.SufficientValueUsdDay>
    </S.ElectricityArticle>
  );
};
