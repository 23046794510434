import React from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as BackIcon } from 'assets/icons/link-icons/back-icon.svg';
import { ICodeVerificationFormProps } from 'types/ui/CodeVerificationForm/code-verification-form-props.interface';

import { MainContentWrapper } from 'components/ui/MainContentWrapper';
import { composeValidators } from 'utils/validators/compose-validators';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { BACKSPACE_KEY_NAME } from './constants/backspace-key-name.const';
import { MAX_INPUT_SYMBOLS_LENGTH } from './constants/max-input-symbols-length.const';
import { useCodeVerificationRefs } from './hooks/useCodeVerificationRefs';
import { checkStringIsNumber } from './utils/check-string-is-number.util';

import * as S from './CodeVerificationForm.styled';

export const CodeVerificationForm: React.FC<ICodeVerificationFormProps> = ({
  title,
  handleSubmit,
  handleBackClick,
  handleCancel,
  t,
}) => {
  const { inputRefs, submitButtonRef } = useCodeVerificationRefs();

  const focusInputByIndex = (index: number) => inputRefs[index].current?.focus();

  const handleCodeChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    index: number,
    handleNativeInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void,
  ) => {
    const isNumberValue = checkStringIsNumber(evt.target.value);

    if (!isNumberValue) {
      evt.preventDefault();
      return;
    }

    handleNativeInputChange(evt);

    if (evt.target.value.length === MAX_INPUT_SYMBOLS_LENGTH && index < inputRefs.length - 1) {
      focusInputByIndex(index + 1);
    }
    if (evt.target.value !== '' && index >= inputRefs.length - 1) {
      submitButtonRef.current?.focus();
    }
  };

  const handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (evt.key === BACKSPACE_KEY_NAME && evt.currentTarget.value === '' && index > 0) {
      focusInputByIndex(index - 1);
    }
  };

  const handleButtonKeyDown = (evt: React.KeyboardEvent<HTMLButtonElement>) => {
    if (evt.key === BACKSPACE_KEY_NAME) {
      focusInputByIndex(inputRefs.length - 1);
    }
  };

  return (
    <S.CodeVerificationSection>
      <MainContentWrapper>
        <S.Title>{title}</S.Title>

        {handleBackClick && (
          <S.BackButton
            variant="borderless"
            text="Return"
            icon={<BackIcon />}
            onClick={handleBackClick}
          />
        )}

        <Form onSubmit={handleSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed}>
              <S.InputGroup>
                <S.Label>{t('login.labelText')}</S.Label>
                <S.FieldsWrapper>
                  {inputRefs.map((inputRef, index) => (
                    <Field
                      name={`digit${index}`}
                      key={`digit${index}`}
                      validate={composeValidators([isRequired, isNumber])}
                    >
                      {({ input, meta }) => (
                        <S.CodeInput
                          {...input}
                          autoFocus={index === 0}
                          ref={inputRef}
                          type="text"
                          minLength={MAX_INPUT_SYMBOLS_LENGTH}
                          maxLength={MAX_INPUT_SYMBOLS_LENGTH}
                          hasErrors={meta.error && submitFailed}
                          onKeyDown={(evt) => handleInputKeyDown(evt, index)}
                          onChange={(evt) => {
                            handleCodeChange(evt, index, input.onChange);
                          }}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  ))}
                </S.FieldsWrapper>
              </S.InputGroup>

              <S.SubmitButton
                ref={submitButtonRef}
                type="submit"
                text="Continue"
                disabled={submitFailed && hasValidationErrors}
                onKeyDown={handleButtonKeyDown}
              />
              {handleCancel && (
                <S.CancelButton
                  type="button"
                  variant="bordered"
                  text="Cancel"
                  onKeyDown={handleButtonKeyDown}
                  onClick={handleCancel}
                />
              )}
            </S.Form>
          )}
        </Form>
      </MainContentWrapper>
    </S.CodeVerificationSection>
  );
};
