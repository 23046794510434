import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

import { cutUrl } from 'utils/formatters/cut-url';
import { AppRoute } from 'utils/route/app-route';

//   App routes:
//   Home: (): string => '/',
//   AffiliateProgram: (): string => '/affiliate-program',
//   Assets: (): string => '/assets',
//   Profile: (): string => '/profile',
//   ProfileTab: (tabName: EProfileTabName): string => `/profile/${tabName}`,
//   Disable2FA: (): string => '/disable-2fa',
//   Purchase: (): string => '/purchase',
//   PurchasePersonalInfo: (): string => '/purchase/personal-info',
//   PurchaseCheckout: (): string => '/purchase/checkout',
//   PurchaseSuccess: (): string => '/purchase/success',
//   PurchaseMinerInfo: (): string => '/purchase/miner-info',
//   Payment: (): string => '/payment',
//   TransactionsHistory: (): string => '/transactions-history',
//   Login: (): string => '/login',
//   Registration: (): string => '/registration',
//   PasswordReset: (): string => '/password-reset',
//   Verification: (): string => '/verification',
//   NotFound: (): string => '/not-found',
//   ReferralRegistration: (): string => '/r/*',

const allowedPaths = [
  AppRoute.AffiliateProgram(),
  AppRoute.Assets(),
  AppRoute.Profile(),
  AppRoute.ProfileTab(EProfileTabName.Orders),
  AppRoute.ProfileTab(EProfileTabName.Personal),
  AppRoute.ProfileTab(EProfileTabName.Security),
  AppRoute.ProfileTab(EProfileTabName.Wallet),
  AppRoute.Disable2FA(),
  AppRoute.Purchase(),
  AppRoute.PurchasePersonalInfo(),
  AppRoute.PurchaseCheckout(),
  AppRoute.PurchaseSuccess(),
  AppRoute.PurchaseMinerInfo(),
  AppRoute.Payment(),
  AppRoute.TransactionsHistory(),
  AppRoute.Login(),
  AppRoute.Registration(),
  AppRoute.PasswordReset(),
  AppRoute.Verification(),
  AppRoute.ReferralRegistration(),
];

export const useReplaceUrl = (): void => {
  const navigate = useNavigate();
  const replacedUrl = cutUrl(window.location.pathname, allowedPaths);

  useEffect(() => {
    if (replacedUrl && !window.location.pathname.includes(replacedUrl)) {
      navigate(replacedUrl);
    }
  }, [replacedUrl, navigate]);
};
