import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/cart/miner-white.svg';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';

import { AppRoute } from 'utils/route/app-route';

import * as S from './BuyAMinerButton.styled';

interface IBuyAMinerButtonProps {
  setIsTooltipOpen: (value: boolean) => void;
}

export const BuyAMinerButton: FC<IBuyAMinerButtonProps> = ({ setIsTooltipOpen }): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <S.BuyAMinerButton
      variant="primary"
      text={t('cart.buyAMiner')}
      icon={<MinerWhiteIcon width={16} height={16} />}
      svgProps={{
        svgFill: 'transparent',
      }}
      onClick={() => {
        navigate(AppRoute.Purchase(), { replace: true });
        dispatch(closePopupAction());
        setIsTooltipOpen(false);
      }}
    />
  );
};
