import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const ElectricityArticle = styled.dl`
  display: grid;
  justify-content: start;
  grid-template-areas:
    'balance-title balance-value button sep payment-title sufficient-title'
    'balance-title balance-value button sep payment-value sufficient-value';
  grid-template-columns: 200px auto 116px repeat(3, auto);
  grid-template-rows: auto auto;
  width: 100%;
  column-gap: 16px;
  row-gap: 2px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    grid-template-columns: 170px auto 116px repeat(3, auto);
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-template-areas: 'balance-title balance-value sufficient-value sufficient-value-usd button';
    grid-template-columns: 140px auto auto auto 1fr;
    grid-template-rows: auto;
    column-gap: 8px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 8px;
    grid-template-areas:
      'balance-title balance-title balance-title'
      'balance-value sufficient-value sufficient-value-usd'
      'button button button';
    grid-template-columns: auto auto 1fr;
    grid-template-rows: repeat(3, auto);
  }
`;

export const BalanceTitle = styled.dt`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 8px;
  grid-area: balance-title;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: lining-nums tabular-nums;

  & svg {
    flex-shrink: 0;
  }

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;

    & > svg {
      display: none;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;

    & > svg {
      display: none;
    }
  }
`;

export const BalanceValue = styled.dd`
  align-self: center;
  grid-area: balance-value;
  min-width: 60px;
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #272727;
`;

export const ReplenishButton = styled(Button)`
  align-self: center;
  grid-area: button;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    justify-self: end;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-top: 4px;
  }
`;

export const Separator = styled.div`
  grid-area: sep;
  width: 1px;
  margin: -16px 16px;
  background-color: #a1a1a1;
  opacity: 0.3;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    margin: -16px 0 -16px auto;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    display: none;
  }
`;

export const PaymentTitle = styled(BalanceTitle)`
  grid-area: payment-title;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    display: none;
  }
`;

export const PaymentValue = styled.dd`
  align-self: end;
  grid-area: payment-value;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #272727;

  @media (max-width: ${BreakPoint.TabletTop}) {
    display: none;
  }
`;

export const SufficientTitle = styled(PaymentTitle)`
  grid-area: sufficient-title;
`;

export const SufficientValue = styled(PaymentValue)`
  grid-area: sufficient-value;

  @media (max-width: ${BreakPoint.TabletTop}) {
    display: inline-flex;
    align-items: center;
    align-self: center;
    padding: 4px;
    border-radius: 8px;
    background-color: #e5eaf1;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-variant-numeric: lining-nums tabular-nums;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-self: start;
  }
`;
export const SufficientValueUsdDay = styled(SText)`
  align-self: center;
  grid-area: sufficient-value-usd;
  text-transform: lowercase;

  @media (min-width: ${BreakPoint.TabletTop}) {
    display: none;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 16px;
    font-variant-numeric: lining-nums tabular-nums;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-self: start;
  }
`;
