import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

export const WebimLinkWrapper = styled.div`
  position: absolute;
  bottom: -36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;

  @media (max-width: ${BreakPoint.MobileTop}) {
    bottom: 24px;
  }
`;

export const WebimLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${EColors.Blue2NewBrand};
`;
