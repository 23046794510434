import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackProfileErrorMessage } from 'constants/notification-messages/fallback-profile-error-message';
import { SuccessMessage } from 'constants/notification-messages/success-message';
import {
  requestOrdersHistory,
  requestProfile,
  requestWalletApprove,
  requestWallets,
  updateProfile,
  updateWallet,
} from 'store/api/cuverse-api/profile/profile.service';
import {
  IOrdersHistoryDataMapped,
  TOrdersHistoryResponse,
} from 'store/api/cuverse-api/profile/types/orders-history-response.interface';
import {
  IProfileData,
  IProfileResponse,
} from 'store/api/cuverse-api/profile/types/profile-response.interface';
import { IUpdateProfileRequestBody } from 'store/api/cuverse-api/profile/types/update-profile-request-body.interface';
import { IUpdateWalletError } from 'store/api/cuverse-api/profile/types/update-wallet-error.interface';
import { IUpdateWalletRequestBody } from 'store/api/cuverse-api/profile/types/update-wallet-request-body.interface';
import { IWalletApproveRequestBody } from 'store/api/cuverse-api/profile/types/wallet-approve-request-body.interface';
import { IWalletApproveResponse } from 'store/api/cuverse-api/profile/types/wallet-approve-response.interface';
import {
  IWallets,
  IWalletsResponse,
} from 'store/api/cuverse-api/profile/types/wallet-response.interface';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';

import { mapOrdersHistoryData, mapProfileData } from 'utils/mapper/mapper';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

import { setNewWalletAddressAction, setWalletChangeStatusAction } from './profile.reducer';

export const requestProfileDataThunkAction = createAsyncThunk(
  'profile/fetchPersonalData',
  async (): Promise<IProfileData> => {
    return await requestProfile()
      .then((response: IProfileResponse) => {
        return mapProfileData(response);
      })
      .catch((error) => {
        notifyError(FallbackProfileErrorMessage.CannotLoadProfileData);
        throw error;
      });
  },
);

export const updateProfileDataThunkAction = createAsyncThunk(
  'profile/updatePersonalData',
  async ({
    body,
    onSuccessCb,
  }: {
    body: IUpdateProfileRequestBody;
    onSuccessCb?: () => void;
  }): Promise<IProfileData> => {
    return await updateProfile(body)
      .then((response: IProfileResponse) => {
        notifySuccess(SuccessMessage.ProfileUpdated);
        onSuccessCb?.();
        return mapProfileData(response);
      })
      .catch((error) => {
        notifyError(FallbackProfileErrorMessage.CannotUpdateProfileData);
        throw error;
      });
  },
);

export const requestWalletsDataThunkAction = createAsyncThunk(
  'profile/fetchWallets',
  async (): Promise<IWallets> => {
    return await requestWallets()
      .then((response: IWalletsResponse) => {
        return response.data;
      })
      .catch((error) => {
        notifyError(FallbackProfileErrorMessage.CannotLoadWallets);
        throw error;
      });
  },
);

export const requestWalletApproveOtpThunkAction = createAsyncThunk(
  'profile/fetchWalletApproveOtp',
  async (body: IWalletApproveRequestBody, { dispatch }): Promise<void> => {
    return await requestWalletApprove(body)
      .then(({ message }: IWalletApproveResponse) => {
        notifySuccess(message);
      })
      .catch((error) => {
        notifyError(FallbackProfileErrorMessage.CannotConfirm);
        dispatch(setWalletChangeStatusAction('not-confirmed'));
        throw error;
      });
  },
);

//update and createWallet have the same endpoints, body, return and leads to the same result. So we can use update thunk action in all places
export const updateWalletsDataThunkAction = createAsyncThunk(
  'profile/updateWallets',
  async (body: IUpdateWalletRequestBody, { dispatch }): Promise<IWallets> => {
    return await updateWallet(body)
      .then((response: IWalletsResponse) => {
        dispatch(setWalletChangeStatusAction('initial'));
        dispatch(setNewWalletAddressAction(null));
        dispatch(closePopupAction());
        notifySuccess(SuccessMessage.WalletUpdated);
        return response.data;
      })
      .catch((error: AxiosError<IUpdateWalletError>) => {
        dispatch(setWalletChangeStatusAction('not-confirmed'));
        if (error.response?.data.data) {
          notifyError(error.response.data.data.replace('_', ' '));
          throw error;
        }
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
          throw error;
        }
        notifyError(FallbackProfileErrorMessage.CannotUpdateWallets);
        throw error;
      });
  },
);

export const requestOrdersHistoryThunkAction = createAsyncThunk(
  'profile/fetchOrdersHistory',
  async (): Promise<IOrdersHistoryDataMapped[]> => {
    return await requestOrdersHistory()
      .then((response: TOrdersHistoryResponse) => {
        return mapOrdersHistoryData(response);
      })
      .catch((error) => {
        throw error;
      });
  },
);
