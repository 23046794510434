import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const SectionWrapper = styled.section<{ $isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 0 auto;

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      flex: 1 0;
      width: 100%;
    `}

  @media (min-width: ${BreakPoint.TabletTop}) {
    width: 600px;

    ${({ $isEmpty }) =>
      $isEmpty &&
      css`
        width: 100%;
      `}
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
  }
`;
