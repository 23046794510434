import { styled } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const SWarningText = styled(SText)<{ $isDisabled?: boolean }>`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;
  ${({ $isDisabled }) => ($isDisabled ? `color: ${EColors.Red1};` : `color: ${EColors.Gray2};`)}
  font-variant-numeric: lining-nums tabular-nums;
  text-wrap: nowrap;
`;
