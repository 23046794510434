import React from 'react';
import { ECryptoPaymentVariant, EPaymentType } from 'store/payment-reducer/payment.reducer';

import { BtcPaymentCard } from './components/BtcPaymentCard/BtcPaymentCard';
import { CardPaymentCard } from './components/CardPaymentCard';
import { CryptoPaymentCard } from './components/CryptoPaymentCard';

interface IPaymentOptionDefaultProps {
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
  balance: number;
  amount: number;
  paymentType: EPaymentType;
  t: (key: string) => string;
}

interface PaymentCardProps extends Omit<IPaymentOptionDefaultProps, 'paymentType'> {
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
  balance: number;
  amount: number;
}

const PaymentCardsComponents: Record<EPaymentType, React.FC<PaymentCardProps>> = {
  [EPaymentType.Crypto]: CryptoPaymentCard,
  [EPaymentType.Card]: CardPaymentCard,
  [EPaymentType.Balance]: BtcPaymentCard,
};

export const PaymentOption: React.FC<IPaymentOptionDefaultProps> = ({
  balance,
  amount,
  paymentType,
  t,
  handlePaymentButtonClick,
}) => {
  const PaymentCardComponent = PaymentCardsComponents[paymentType];

  return (
    <PaymentCardComponent
      handlePaymentButtonClick={handlePaymentButtonClick}
      balance={balance}
      amount={amount}
      t={t}
    />
  );
};
