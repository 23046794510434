import { FC } from 'react';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { CartEmptyContent } from 'components/logic/Cart/components/ui/CartEmptyContent';
import { CartHeader } from 'components/logic/Cart/components/ui/CartHeader';
import { CartList } from 'components/logic/Cart/components/ui/CartList';
import { CheckoutButton } from 'components/logic/Cart/components/ui/CheckoutButton';

import * as S from './CartTooltip.styled';

interface ICartTooltipProps {
  isTooltipOpen: boolean;
  isEmpty: boolean;
  isCartPending: boolean;
  cartListWithMinersData: (Omit<ICartItemMapped, 'productId'> & {
    id: number;
  } & IMinerDataMapped)[];
  handleIncrement: (id: number, quantity: number, maxQuantity: number) => void;
  handleDecrement: (id: number) => void;
  handleDeleteAllSameItems: (id: number) => void;
  handleDeleteAllItems: () => void;
  handleTooltipMouseOver?: () => void;
  handleTooltipMouseLeave?: () => void;
  handleTooltipClose: () => void;
  handleCheckoutClick: () => void;
  setIsTooltipOpen: (value: boolean) => void;
  t: (key: string) => string;
}

export const CartTooltip: FC<ICartTooltipProps> = ({
  isTooltipOpen,
  isEmpty,
  isCartPending,
  cartListWithMinersData,
  handleIncrement,
  handleDecrement,
  handleDeleteAllSameItems,
  handleDeleteAllItems,
  handleTooltipMouseOver,
  handleTooltipMouseLeave,
  handleTooltipClose,
  handleCheckoutClick,
  setIsTooltipOpen,
  t,
}) => {
  return (
    <S.CartTooltipWrapper
      $isVisible={isTooltipOpen}
      onMouseLeave={handleTooltipMouseLeave}
      onMouseOver={handleTooltipMouseOver}
    >
      <S.TooltipContent>
        {!isEmpty ? (
          <>
            <CartHeader
              isEmpty={isEmpty}
              handleDeleteAllItems={handleDeleteAllItems}
              handleTooltipClose={handleTooltipClose}
              t={t}
            />
            <CartList
              cartListWithMinersData={cartListWithMinersData}
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              handleDeleteAllSameItems={handleDeleteAllSameItems}
            />
            <CheckoutButton
              isEmpty={isEmpty}
              isCartPending={isCartPending}
              handleCheckoutClick={handleCheckoutClick}
            />
          </>
        ) : (
          <CartEmptyContent
            renderHeader={
              <CartHeader
                isEmpty={true}
                handleDeleteAllItems={handleDeleteAllItems}
                handleTooltipClose={handleTooltipClose}
                t={t}
              />
            }
            setIsTooltipOpen={setIsTooltipOpen}
            t={t}
          />
        )}
      </S.TooltipContent>
    </S.CartTooltipWrapper>
  );
};
