import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChatIcon } from 'assets/icons/menu-icons/chats-svg.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/menu-icons/logout.svg';

import { MAIN_SITE_LINK } from './constants/main-site-link';

import * as S from './NavigationFooter.styled';

interface INavigationFooterProps {
  handleLogout: () => void;
  isLoggingOut: boolean;
}

export const NavigationFooter: React.FC<INavigationFooterProps> = ({
  handleLogout,
  isLoggingOut,
}) => {
  const { t } = useTranslation();

  return (
    <S.FooterLinksWrapper>
      <S.InnerLinksWrapper>
        <S.HelpLink className="webim_button webim_basic_button" to="#">
          <ChatIcon />
          {t('menu.help')}
        </S.HelpLink>
        <S.LogoutButton onClick={handleLogout} disabled={isLoggingOut}>
          <LogoutIcon />
          {t('menu.logOut')}
        </S.LogoutButton>
      </S.InnerLinksWrapper>
      <S.MainSiteLink href={MAIN_SITE_LINK} target="_blank">
        {t('menu.goToMainSite')} →
      </S.MainSiteLink>
    </S.FooterLinksWrapper>
  );
};
