import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IPaymentRequestBody } from './types/payment-request-body.interface';
import { IPaymentBtcResponse, IPaymentResponseData } from './types/payment-response.interface';
import { PaymentRoute } from './payment.route';

export const requestInnerPayment = async (
  body: IPaymentRequestBody,
): Promise<IPaymentBtcResponse> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });

export const requestOuterPayment = async (
  body: IPaymentRequestBody,
): Promise<IPaymentResponseData> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });
