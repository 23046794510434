import { css, styled } from 'styled-components';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { EShadows } from 'styles/style-variables/shadows';

export const CartTooltipWrapper = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: 1;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      opacity: 0;

      * {
        cursor: default;
        pointer-events: none;
      }
    `}
  transition: opacity ${AnimationDuration.TooltipOpening};
  pointer-events: all;
`;

export const TooltipContent = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${EColors.BlueGrayBackMid};
  border-radius: 24px;
  background: ${EColors.White};
  box-shadow: ${EShadows.basic};

  @media (min-width: ${BreakPoint.TabletTop}) {
    padding: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;
