import { SListTitle } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const HashrateAmountWrapper = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
    min-width: 163px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 4px;
  }
`;

export const TittleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > svg {
    width: 32px;
    height: 32px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {

    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 4px;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Title = styled(SListTitle)`
  max-width: 65px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    text-wrap: nowrap;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 8px;
  }
`;

export const AmountValue = styled(SText)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const MinersCount = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${EColors.Gray5};

  & > svg {
    width: 16px;
    height: 16px;
    padding: 0.833px 2px 1.333px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 2px;
    padding: 2px 4px;

    & > svg {
      width: 12px;
      height: 12px;
      padding: 0.625px 1.5px 1px;
    }
  }
`;

export const CountNumber = styled(SText)`
  font-size: 14px;
  line-height: 18px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }
`;
